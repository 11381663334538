import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearAuthStatus, clearUserDetails } from './Redux/Action/userAction';

export const getToken = () => {
	const activeToken = localStorage.getItem('active');
	return activeToken ? JSON.parse(activeToken) : null;
};

export const removeToken = () => {
	localStorage.removeItem('active');
};

export const useHandleToken = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const unhandleToken = (error: any) => {
		console.log(error?.status);
		if (error?.status && error?.status === 401) {
			removeToken();
			dispatch(clearAuthStatus());
			dispatch(clearUserDetails());
			// Navigate after 1 second
			navigate('/logging-out'); // Redirect to the login page
		}
	};

	return { unhandleToken };
};
