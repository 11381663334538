import React, { useEffect } from 'react';
import { ChatContext } from '../../../../Contexts';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getToken } from '../../../../Helper/GetToken';
import { getCurrentTimeStamp } from '../../../../Helper';
import { Box, Typography } from '@mui/material';
import simulationIcon from '../../../../Assets/Images/simulation_icon_v2.png';
import preparationIcon from '../../../../Assets/Images/preparation_icon_v2.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const buttonStyles = {
	width: '250px',
	height: '172px',
	borderRadius: '8px',
	border: '2px solid #1C75BA',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',

	'&:hover': {
		border: '2px solid #1C75BA',
		backgroundColor: '#e1f2ff',
		color: '#FFFFFF'
	}
};

const SelectionStep = ({
	handleNext,
	firstInteractionFromCoach,
	setSelectionStep
}: {
	handleNext: (step: number) => void;
	firstInteractionFromCoach: () => void;
	setSelectionStep: (step: boolean) => void;
}) => {
	const { chatLoading } = React.useContext(ChatContext);
	// const [summaryText, setSummaryText] = React.useState('');
	const { roleName } = useSelector((state: any) => state?.role);

	// useEffect(() => {
	// 	const timeout = setTimeout(() => {
	// 		firstInteractionFromCoach();
	// 	}, 0);
	// 	return () => clearTimeout(timeout);
	// }, []);

	return (
		<Box sx={{ width: '100%', maxWidth: 'auto', paddingX: '20px', mt: '-60px' }}>
			<Typography sx={{ mb: 2, cursor: 'pointer' }} color={'#1C75BA'} onClick={() => setSelectionStep(false)}>
				<ArrowBackIcon fontSize="small" sx={{ mr: 0.5 }} /> Back
			</Typography>
			<Typography sx={{ marginBottom: '10px', fontWeight: '700', fontSize: '28px' }}>
				Please choose your next step:
			</Typography>
			You can have a preparation session with a coach regarding the simulation or Start the simulation
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', my: '20px' }}>
				<Box
					onClick={async () => {
						if (!chatLoading) {
							handleNext(1);
							await firstInteractionFromCoach();
						}
					}}
					sx={{ ...buttonStyles, cursor: chatLoading ? 'not-allowed' : 'pointer' }}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<img width={80} height={80} src={preparationIcon} alt="Preparation Icon" />
						<Typography sx={{ fontSize: '20px', mt: '8px', fontWeight: '700', color: 'black' }}>Preparation</Typography>
					</div>
				</Box>
				<Box
					onClick={() => !chatLoading && handleNext(2)}
					sx={{ ...buttonStyles, cursor: chatLoading ? 'not-allowed' : 'pointer' }}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<img width={80} height={80} src={simulationIcon} alt="Simulation Icon" />
						<Typography sx={{ fontSize: '20px', mt: '8px', fontWeight: '700', color: 'black', textAlign: 'center' }}>
							Simulation
						</Typography>
					</div>
				</Box>
			</Box>
			{/* <Typography sx={{ marginBottom: '10px', fontWeight: '600', fontSize: '20px' }}>Simulation Summary</Typography> */}
			{/* {chatLoading ? (
				'Loading...'
			) : (
				<div style={{ width: '60%' }}>
					{summaryText?.length > 0 && (
						<div
							dangerouslySetInnerHTML={{
								__html: summaryText
							}}
						/>
					)}
				</div>
			)} */}
			{roleName?.description?.length > 0 && (
				<Typography sx={{ marginBottom: '10px', fontWeight: '600', fontSize: '20px' }}>
					Simulation Description
				</Typography>
			)}
			<div style={{ width: '60%' }}>
				{roleName?.description?.length > 0 && (
					<div
						dangerouslySetInnerHTML={{
							__html: roleName?.description
						}}
					/>
				)}
			</div>
		</Box>
	);
};

export default SelectionStep;
