/* eslint-disable @typescript-eslint/no-empty-function */

import React, { FC } from 'react';
import { useSSE } from 'react-hooks-sse';

import { ISSEContextType } from '../types';
import { getToken, useHandleToken } from '../Helper/GetToken';
import axios from 'axios';

export enum ChannelE {
	'core' = 'core',
	'coachMessage' = 'coachMessage',
	'clientMessage' = 'coachMessage',
	'feedbackChannel' = 'feedbackChannel',
	'recommendationChannel' = 'recommendationChannel',
	'simulationPrepare' = 'simulationPrepare',
	'simulation' = 'simulation',
	'debrief' = 'debrief'
}

type Action = {
	channel: ChannelE;
	message: any;
};
// Create a context with the specified type
export const SSEContext = React.createContext<ISSEContextType>({
	state: null,
	postMessage: async () => {}
});

// Create a provider component to wrap your app and provide the context values
export const SSEContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const state = useSSE<{ [key: string]: any }, Action>(
		'message',
		{
			data: {},
			core: {},
			coachMessage: null,
			clientMessage: null,
			feedbackChannel: null,
			recommendationChannel: null
		},
		{
			parser(input) {
				const data = JSON.parse(input);
				return data;
			},
			stateReducer(state, action) {
				const { channel, message } = action.data;
				switch (channel) {
					case ChannelE.core:
						return { ...state, core: { clientId: message.clientId } };
					case ChannelE.coachMessage:
						return { ...state, coachMessage: message };
					case ChannelE.simulationPrepare:
						return { ...state, simulationPrepare: message };
					case ChannelE.simulation:
						return { ...state, simulation: message };
					case ChannelE.debrief:
						return { ...state, debrief: message };
					case ChannelE.clientMessage:
						return { ...state, clientMessage: message };
					case ChannelE.feedbackChannel:
						return { ...state, feedbackChannel: message };
					case ChannelE.recommendationChannel:
						return { ...state, recommendationChannel: message };
					default:
						return state;
				}
			}
		}
	);

	const postMessage = async (channelName: ChannelE, message: any, url = 'postmsg') => {
		if (state.core.clientId && token) {
			return axios
				.post(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/${url}`,
					{
						channalName: channelName,
						data: message
					},
					{
						headers: {
							Authorization: token,
							'sse-client-id': state.core.clientId
						}
					}
				)
				.then((res: { data: any }) => {
					return res.data;
				})
				.catch((err: any) => {
					console.log(err);
					unhandleToken(err);
				});
		}
	};

	return <SSEContext.Provider value={{ state, postMessage }}>{children}</SSEContext.Provider>;
};
