import {
	Alert,
	Box,
	Button,
	Modal,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import './Company.css';
import { AddCompany } from './AddCompany';
import { CompanyType, EditCompany } from './EditCompany';
export const CompanyTable = () => {
	const [allCompany, setAllCompany] = useState([]);
	const [isRefresh, setIsRefresh] = useState(false);
	const [open, setOpen] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);
	const [message, setMessage] = useState('');
	const [editOpen, setEditOpen] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [selectedCompany, setSelectedCompany] = useState<CompanyType>();
	const vertical = 'top';
	const horizontal = 'right';
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getAllCompany();
	}, [isRefresh]);
	const getAllCompany = async () => {
		await axios
			.get(`${baseUrl}/getAllCompanies`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				setAllCompany(res.data.companies);
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const editHandler = async (data: CompanyType) => {
		setSelectedCompany(data);
		setEditOpen(true);
	};
	const deleteHandler = async (id: number | undefined) => {
		try {
			await axios
				.delete(`${baseUrl}/company/${id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data.success) {
						setIsRefresh(!isRefresh);
					} else {
						setMessage(res.data.message);
						setOpenSnack(true);
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};
	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Box>
						<Typography variant="h6">Company</Typography>
					</Box>
					<Box>
						<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
							Add
						</Button>
					</Box>
				</Box>
				<Box>
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}>
								<TableRow sx={{ textWrap: 'nowrap' }}>
									<TableCell align="left">Company Name</TableCell>
									<TableCell align="left">Contact Person Name</TableCell>
									<TableCell align="left">Contact Person Email</TableCell>
									<TableCell align="left">Edit</TableCell>
									<TableCell align="left">Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(allCompany) &&
									allCompany.length > 0 &&
									allCompany.map((items: CompanyType, index: number) => {
										return (
											<>
												<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
													<TableCell align="left">{items.name}</TableCell>
													<TableCell align="left">
														{items?.contact
															? `${items.contact.first_name || ''} ${items.contact.last_name || ''}`.trim()
															: ''}
													</TableCell>
													<TableCell align="left">{items?.contact?.email}</TableCell>
													<TableCell align="left">
														<Button
															variant="outlined"
															color="secondary"
															size="small"
															startIcon={<EditIcon />}
															onClick={() => editHandler(items)}>
															Edit
														</Button>
													</TableCell>
													<TableCell align="left">
														<Button
															variant="outlined"
															size="small"
															startIcon={<DeleteIcon />}
															onClick={() => deleteHandler(items?.id)}>
															Delete
														</Button>
													</TableCell>
												</TableRow>
											</>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<AddCompany setOpen={setOpen} setIsRefresh={setIsRefresh} isRefresh={isRefresh} />
			</Modal>
			<Modal open={editOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<EditCompany
					setEditOpen={setEditOpen}
					setIsRefresh={setIsRefresh}
					selectedCompany={selectedCompany}
					isRefresh={isRefresh}
				/>
			</Modal>
			<Snackbar
				open={openSnack}
				autoHideDuration={5000}
				onClose={() => setOpenSnack(!openSnack)}
				sx={{ padding: '10px 5px' }}
				anchorOrigin={{ vertical, horizontal }}>
				<Alert
					onClose={() => setOpenSnack(!openSnack)}
					severity="error"
					variant="outlined"
					sx={{
						width: '100%',
						padding: '10px 5px',
						border: '1px solid rgba(5, 10, 58, 0.3)',
						borderRadius: '5px',
						background: 'white'
					}}>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};
