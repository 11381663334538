import React from 'react';
import smallLogo from '../../../Assets/Images/smallLogo.png';
import './InsideNav.css';
import Breadcrumb from '../../BreadCrum/breadCrumb';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '../../Logout/Logout';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';

interface InsideNavProps {
	handleDrawerToggle: () => void;
}

export const InsideNav: React.FC<InsideNavProps> = ({ handleDrawerToggle }) => {
	return (
		<>
			<section className="navInside-section">
				<div className="container-fluid">
					<div className="inside-nav-box">
						<div className="inside-nav">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { md: 'none' } }}>
								<MenuIcon />
							</IconButton>
							<img src={smallLogo} alt="loading" />
							<p>SIMSOLA</p>
						</div>
						<div>
							<div className="breadcrumb-box">
								<Breadcrumb />
							</div>
						</div>
						<div style={{ marginLeft: 'auto' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Link to="/user-instruction" rel="noopener noreferrer">
									<HelpIcon sx={{ mr: 2, cursor: 'pointer' }} color="primary" />
								</Link>
								<Logout />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
