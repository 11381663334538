import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { setRole } from '../../../Helper/Redux/Action/userAction';

interface PreparationHeaderProps {
	title?: string;
	para?: string;
	dbName?: string;
}
const PreparationHeader: React.FC<PreparationHeaderProps> = () => {
	const { roleName } = useSelector((state: any) => state?.role);
	const [open, setOpen] = React.useState(false);
	const [loadingIframe, setLoadingIframe] = React.useState(true);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { category } = useSelector((state: any) => state?.roleCategory) || [];
	const dispatch = useDispatch();
	const { unhandleToken } = useHandleToken();
	const handleClickOpen = () => {
		setOpen(true);
		setLoadingIframe(true);
	};
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();

	useEffect(() => {
		if (category) {
			getScenarios();
		}
	}, []);

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/getUserSubScenarios`, {
				headers: {
					Authorization: token
				},
				params: { id: category?.scenario_id }
			})
			.then(res => {
				if (res.data) {
					const setRoleData = res.data.find((data: any) => data.scenario_id === roleName?.scenario_id);
					dispatch(setRole(setRoleData));
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box sx={styles.Box}>
					<div>
						{/* <Typography sx={styles.Title}>{title}</Typography>
        <Typography sx={styles.Para}>{para}</Typography> */}
						<Typography sx={styles.Title}>Simulation Scenario: {roleName?.scenario_name}</Typography>{' '}
						{/* <Typography sx={styles.Title}>{roleName?.name}</Typography> */}
						<Typography sx={styles.Para}>
							{roleName?.description?.split('\n').map((line: string, index: number) => (
								<React.Fragment key={index}>
									You are in the preparation phase. Click NEXT button to start the simulation anytime. Here is the
									scenario: {line}
									<br />
								</React.Fragment>
							))}
						</Typography>
					</div>
				</Box>
				<div style={{ width: '30%', display: 'flex', justifyContent: 'end' }}>
					{roleName?.video_url && (
						<Button
							onClick={handleClickOpen}
							variant="outlined"
							sx={{ width: '70%', textTransform: 'capitalize', ...styles.Button }}
						>
							Review Backstory
						</Button>
					)}
				</div>
			</div>
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
				<Typography
					variant="h5"
					sx={{ marginTop: '8px', display: 'flex', justifyContent: 'center' }}
					id="responsive-dialog-title"
				>
					{'The Backstory'}
				</Typography>
				{roleName?.video_url && roleName.video_url.includes('scenario_videos') && (
					<DialogContentText style={{ marginTop: '4px', display: 'flex', justifyContent: 'center' }}>
						Click on video to start
					</DialogContentText>
				)}
				<DialogContent
					sx={{
						width: '600px',
						height: roleName?.video_url && roleName.video_url.includes('scenario_videos') ? '100%' : '600px'
					}}
				>
					<DialogContentText sx={{ height: '100%' }}>
						{roleName?.video_url && roleName.video_url.includes('scenario_videos') ? (
							<video
								// src={`${process.env.PUBLIC_URL}/Videos/John-feedback.mp4`}
								src={roleName?.video_url}
								// autoPlay
								controls
								width={'100%'}
							/>
						) : (
							<>
								{loadingIframe && <p>Loading...</p>}
								<iframe
									width={'100%'}
									height={'100%'}
									src={roleName?.video_url}
									onLoad={() => setLoadingIframe(false)}
									onError={() => setLoadingIframe(false)}
									loading="lazy"
								/>
							</>
						)}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

const styles = {
	Box: {
		maxWidth: '70%',
		'@media (max-width: 1024px)': {
			maxWidth: '90%'
		}
	},
	Title: {
		color: '#161616',
		fontFamily: 'IBM Plex Sans',
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: '36px',
		pt: '15px',
		'@media (max-width: 1600px)': {
			fontSize: '22px',
			lineHeight: 'normal'
		}
	},
	Para: {
		color: '#161616',
		fontFamily: 'Outfit',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		pt: '15px  ',
		'@media (max-width: 1600px)': {
			fontSize: '15px',
			lineHeight: 'normal'
		},
		height: '85px',
		overflowY: 'auto'
	},
	Button: {
		borderRadius: '100px',
		border: '1px solid #1C75BA',
		p: '4px 20px',
		fontSize: '18px',
		fontWeight: 500,
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};
export default PreparationHeader;
