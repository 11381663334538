import {
	Box,
	Button,
	IconButton,
	Snackbar,
	SnackbarCloseReason,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../Helper/GetToken';
import getUser from '../../Helper/getUser';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedUser } from '../../Helper/Redux/Action/userAction';
import ExcelJS from 'exceljs';
import CloseIcon from '@mui/icons-material/Close';
interface Scenario {
	scenario_id: number;
	scenario_name: string;
	role_name: string | null;
	video_url: string | null;
	category: string;
	description: string;
	parent_id: number | null;
	voice_modal: string | null;
}

interface Cohort {
	id: number;
	name: string;
	start_date: string | null;
	end_date: string | null;
	created_at: string;
	deleted_at: string | null;
	deleted_by: number | null;
	created_by: number;
}

interface StatisticsData {
	id: number;
	time_spended: number;
	average_session_score: number;
	scenario: Scenario;
	cohort: Cohort;
	highest_score: number;
	session: number;
	levelPassed?: boolean;
}

interface User {
	id: number;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name: string;
	created_at: string;
	deleted_at: string | null;
}

interface Statistics {
	id: number;
	name: string;
	start_date: string;
	sessions: number;
	total_time: number;
	coach_total_time: number;
	average_score: number;
	communication: number | null;
	coaching: number | null;
	total_simulation: number;
	statisticsdata: StatisticsData[];
	user: User;
	Strengths: string;
	Weaknesses: string;
}

const SummaryTable = () => {
	const [statisticData, setStatisticData] = useState<Statistics[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [rows, setRows] = useState<(string | number)[][]>([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();
	const StyledLink = styled(Link)({
		textDecoration: 'none',
		color: 'inherit'
	});
	const [open, setOpen] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const dispatch = useDispatch();
	useEffect(() => {
		getStatistic();
	}, []);

	const getStatistic = async () => {
		if (user.id) {
			try {
				const res = await axios.get(`${baseUrl}/getstatistics/${user.id}`, {
					headers: {
						Authorization: token
					}
				});
				if (res.data) {
					setStatisticData(res.data);
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		if (statisticData.length > 0) {
			const allScenarios = getAllScenarios(statisticData);
			setHeaders(generateTableHeaders(allScenarios));
			setRows(generateTableRows(statisticData, allScenarios));
		}
	}, [statisticData]);

	console.log(rows);

	const scenarioIds: number[] = [];

	// Function to get all unique scenario names and IDs
	const getAllScenarios = (data: Statistics[]): Set<string> => {
		const scenarios = new Set<string>();

		data.forEach(stat => {
			stat.statisticsdata.forEach(statData => {
				if (!scenarioIds.includes(statData.scenario.scenario_id)) {
					scenarioIds.push(statData.scenario.scenario_id);
				}
				scenarios.add(statData.scenario.scenario_name);
			});
		});

		return scenarios;
	};

	// Function to generate table headers
	const generateTableHeaders = (allScenarios: Set<string>): string[] => {
		const headers = ['Name'];

		return headers.concat(Array.from(allScenarios).flatMap(scenario => [`${scenario}`])).concat(['Total Sessions']);
	};

	// Function to generate table rows
	const generateTableRows = (data: Statistics[], allScenarios: Set<string>): (string | number)[][] => {
		return data.map(stat => {
			const row: any[] = [];
			row.push(stat.name);

			const scenarioData: {
				[key: string]: {
					session: number | string;
					levelPassed: boolean;
				};
			} = {};

			Array.from(allScenarios).forEach(scenario => {
				scenarioData[scenario] = {
					session: '0',
					levelPassed: false
				};
			});

			// Populate scenario data with actual values where available
			stat.statisticsdata.forEach(statData => {
				const scenario = statData?.scenario.scenario_name;
				scenarioData[scenario] = {
					session: statData?.session,
					levelPassed: statData?.levelPassed ?? false
				};
			});

			let totalSessions = 0;
			Object.keys(scenarioData).forEach(scenario => {
				totalSessions = totalSessions + +scenarioData[scenario]?.session;
				row.push(scenarioData[scenario]);
			});

			row.push(totalSessions);
			return row;
		});
	};

	const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	const action = (
		<React.Fragment>
			{/* <Button color="secondary" size="small" onClick={handleClose}>
				UNDO
			</Button> */}
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);
	return (
		<div>
			{rows.length ? (
				<Box>
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#cfe9fd52',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow>
									{headers.map(header => (
										<TableCell align="left" key={header} style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
											{header}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, rowIndex) => (
									<TableRow key={rowIndex}>
										{row.map((cell: any, cellIndex) => (
											<TableCell
												align="left"
												key={cellIndex}
												style={{
													border: '1px solid rgba(224, 224, 224, 1)',
													backgroundColor: cell?.levelPassed ? 'green' : 'transparent',
													color: cell?.levelPassed ? 'white' : 'black'
												}}
											>
												{typeof cell == 'object' ? cell?.session : cell}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				'No Data Found'
			)}
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={notificationMessage}
				action={action}
			/>
		</div>
	);
};

export default SummaryTable;
