import React from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Avatar,
	Paper
} from '@mui/material';

interface Badge {
	id: number;
	name: string;
	data: string; // Base64 image data
}

interface User {
	id: number;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name?: string;
}

interface PassedScenario {
	badge: Badge;
	category: string;
	description: string;
	scenario_id: number;
	scenario_name: string;
}

interface BadgeData {
	user: User;
	cohort: {
		id: number;
		name: string;
		start_date: Date | null;
		end_date: Date | null;
	};
	passedScenarios: PassedScenario[];
}

interface UserWithBadgeProps {
	badgeData: BadgeData[];
	selectedCohort: any;
}

const UserWithBadge: React.FC<UserWithBadgeProps> = ({ badgeData, selectedCohort }) => {
	console.log(Array.isArray(badgeData));
	console.log(selectedCohort.id);

	return (
		<Box>
			<TableContainer component={Paper} sx={{ width: '100%', margin: 'auto', boxShadow: 1, borderRadius: 1 }}>
				<Table sx={{ width: '100%', minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottom: '2px solid #e0e0e0' }}>
								User Name
							</TableCell>
							<TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottom: '2px solid #e0e0e0' }}>
								Badges
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{badgeData
							.filter(items => items?.cohort?.id === selectedCohort?.id)
							.map((data, index) => {
								if (!data?.user) {
									console.warn(`Skipping row at index ${index} due to missing user data.`);
									return null;
								}

								return (
									<TableRow
										key={data.user.id}
										sx={{
											'&:nth-of-type(odd)': { backgroundColor: '#fafafa' },
											'&:hover': { backgroundColor: '#f5f5f5' }
										}}
									>
										<TableCell sx={{ padding: 1.5, fontSize: '0.95rem' }}>
											{`${data.user.first_name || ''} ${data.user.last_name || ''}`}
										</TableCell>
										<TableCell sx={{ padding: 1.5, fontSize: '0.95rem', display: 'flex', alignItems: 'center' }}>
											{Array.isArray(data?.passedScenarios) && data.passedScenarios.length > 0 ? (
												data.passedScenarios.map((scenario, idx) => (
													<Avatar
														key={idx}
														src={scenario?.badge?.data || ''}
														alt={scenario?.badge?.name || 'Badge'}
														sx={{ width: 30, height: 30, marginRight: 1 }}
													/>
												))
											) : (
												<Typography variant="body2" color="textSecondary">
													No Badge
												</Typography>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						{/* Display a message if no users are found for the selected cohort */}
						{badgeData.filter(items => items?.cohort?.id === selectedCohort?.id).length === 0 && (
							<TableRow>
								<TableCell colSpan={2} align="center">
									<Typography variant="body2" color="textSecondary">
										No users found for the selected cohort.
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default UserWithBadge;
