import React, { useCallback, useEffect, useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {
	Box,
	Button,
	debounce,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	overflowY: 'scroll',
	maxHeight: '95vh'
};
interface EditCohortProps {
	setEditOpen: (editOpen: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	selectedCohort: any;
	isRefresh: boolean;
}
type AddCohortState = {
	name: string;
	company_id: number;
	scenario_ids: number[];
	subScenario_ids: number[];
	scenarioSequence: number[];
	scenario_passing_score: number;
	min_passed_scenario: number;
	maxUser: number;
};
const EditCohort: React.FC<EditCohortProps> = ({ setEditOpen, setIsRefresh, selectedCohort, isRefresh }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [allScenerio, setAllScenerio] = useState([]);
	const [allCompany, setAllCompany] = useState([]);
	const [subScenerio, setSubScenerio] = useState([]);
	const [addcohort, setAddcohort] = useState<AddCohortState>({
		name: '',
		company_id: 0,
		scenario_ids: [],
		subScenario_ids: [],
		scenarioSequence: [],
		scenario_passing_score: 0,
		min_passed_scenario: 0,
		maxUser: 0
	});
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		setIsLoading(true);
		getAllCompany();
		getAllScenerio();
		if (selectedCohort) {
			setAddcohort(prevState => ({
				...prevState,
				name: selectedCohort.name || '',
				company_id: selectedCohort.company?.id || 0,
				scenario_passing_score: selectedCohort.scenario_passing_score,
				min_passed_scenario: selectedCohort.min_passed_scenario,
				maxUser: selectedCohort.maxUser || 0,
				scenario_ids: selectedCohort.scenario.map((scenario: { scenario_id: number }) => scenario.scenario_id),
				subScenario_ids: selectedCohort.subScenario.map((scenario: { scenario_id: number }) => scenario.scenario_id),
				scenarioSequence: selectedCohort.scenarioSequences
					.sort((a: { sequence: number }, b: { sequence: number }) => a.sequence - b.sequence)
					.map((item: { scenario: { scenario_id: number } }) => item?.scenario?.scenario_id)
			}));
		}
	}, []);
	// console.log(addcohort);

	useEffect(() => {
		if (addcohort.scenario_ids.length > 0) {
			debouncedGetSubScenerio();
		} else {
			setSubScenerio([]);
		}
		// console.log(addcohort.scenario_ids);
	}, [addcohort.scenario_ids]);

	const getSubScenerio = async () => {
		try {
			const response = await axios.post(
				`${baseUrl}/api/scenario/getSubScenario`,
				{ ids: addcohort.scenario_ids },
				{
					headers: {
						Authorization: token
					}
				}
			);

			if (response.data) {
				setSubScenerio(response.data);
				if (addcohort.subScenario_ids.length > 0) {
					setAddcohort(prevState => {
						const newSubScenarioIds = response.data
							.filter((item: { scenario_id: any }) => prevState.subScenario_ids.includes(item.scenario_id))
							.map((item: { scenario_id: any }) => item.scenario_id);
						return { ...prevState, subScenario_ids: newSubScenarioIds };
					});
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};
	const debouncedGetSubScenerio = useCallback(debounce(getSubScenerio, 300), [addcohort.scenario_ids]);
	const getAllCompany = async () => {
		await axios
			.get(`${baseUrl}/getAllCompanies`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setAllCompany(res.data.companies);
					// setAllScenerio(res.data);
					// setMembers(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
		setIsLoading(false);
	};

	const getAllScenerio = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setAllScenerio(res.data);
					// setMembers(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
		setIsLoading(false);
	};
	const inputHandler = (e: { target: { name: string; value: any } }) => {
		setAddcohort({ ...addcohort, [e.target.name]: e.target.value });
	};

	const scenerioHanlder = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value, 10);
		setAddcohort(prevState => {
			const newScenarioIds = e.target.checked
				? [...prevState.scenario_ids, value]
				: prevState.scenario_ids.filter(id => id !== value);
			const newScenarioSequence = e.target.checked
				? [...prevState.scenarioSequence, value]
				: prevState.scenarioSequence.filter(id => id !== value);
			return {
				...prevState,
				scenario_ids: newScenarioIds,
				scenarioSequence: newScenarioSequence
			};
		});
	};
	const subScenerioHanlder = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value, 10);
		if (e.target.checked) {
			setAddcohort(prevState => ({
				...prevState,
				subScenario_ids: [...prevState.subScenario_ids, value]
			}));
		} else {
			setAddcohort(prevState => ({
				...prevState,
				subScenario_ids: prevState.subScenario_ids.filter(id => id !== value)
			}));
		}
	};
	const handleDragStart = (e: React.DragEvent<HTMLLIElement>, index: number) => {
		e.dataTransfer.setData('text/plain', index.toString());
	};

	const handleDrop = (e: React.DragEvent<HTMLLIElement>, index: number) => {
		e.preventDefault();
		const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
		setAddcohort(prevState => {
			const newScenarioSequence = [...prevState.scenarioSequence];
			const [removed] = newScenarioSequence.splice(draggedIndex, 1);
			newScenarioSequence.splice(index, 0, removed);
			return { ...prevState, scenarioSequence: newScenarioSequence };
		});
	};
	const allowDrop = (e: React.DragEvent<HTMLLIElement>) => {
		e.preventDefault();
	};
	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisabled(!isDisabled);
		if (addcohort.scenario_ids.length === 0) {
			setIsDisabled(false);
			alert('select scenerios');
			return;
		}
		if (addcohort.subScenario_ids.length === 0) {
			setIsDisabled(false);
			alert('select Sub scenerios');
			return;
		}

		try {
			await axios
				.put(`${baseUrl}/api/admin/editcohort/${selectedCohort.id}`, addcohort, {
					headers: {
						Authorization: token
					}
				})
				.then(() => {
					setIsRefresh(!isRefresh);
					setEditOpen(false);
				})
				.catch(e => {
					console.log(e);
					setEditOpen(false);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};

	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Edit Cohort</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setEditOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			{isLoading ? (
				<Typography>Loading data, please wait...</Typography>
			) : (
				<>
					<form onSubmit={submitHandler}>
						<FormControl fullWidth sx={{ gap: 2 }}>
							<Box>
								<Typography>Company Name</Typography>
								<Select name="company_id" value={addcohort.company_id} onChange={inputHandler} fullWidth required>
									{allCompany.length > 0 &&
										allCompany.map((items: { id: string; name: string }, index: number) => {
											return (
												<MenuItem value={items?.id} key={index}>
													{items?.name}
												</MenuItem>
											);
										})}
								</Select>
							</Box>
							<Box>
								<Typography>Cohort Name</Typography>
								<TextField name="name" required value={addcohort.name} onChange={inputHandler} fullWidth />
							</Box>
							<Box>
								<Typography>Secenario Categories</Typography>
								<ul className="ks-cboxtags ">
									{allScenerio &&
										allScenerio
											.filter((item: { parent_id: string }) => item.parent_id === null)
											.map((items: { scenario_id: string; scenario_name: string }, index: number) => {
												return (
													<li key={index} className="mr-1">
														<input
															type="checkbox"
															id={items.scenario_id}
															value={items.scenario_id}
															onChange={scenerioHanlder}
															checked={addcohort.scenario_ids.includes(+items.scenario_id)}
														/>
														<label htmlFor={items.scenario_id}>{items.scenario_name}</label>
													</li>
												);
											})}
									{/* <li className='mr-1'><input type="checkbox" id="checkboxFour" value="Moondancer" /><label for="checkboxFour">Moondancer</label></li> */}
								</ul>
							</Box>
							<Box>
								<Typography>Sub Secenario Categories</Typography>
								<ul className="ks-cboxtags ">
									{subScenerio.length > 0 &&
										subScenerio.map((items: { scenario_id: string; scenario_name: string }, index: number) => {
											return (
												<li key={index} className="mr-1">
													<input
														type="checkbox"
														id={items.scenario_id}
														value={items.scenario_id}
														onChange={subScenerioHanlder}
														checked={addcohort.subScenario_ids.includes(+items.scenario_id)}
													/>
													<label htmlFor={items.scenario_id}>{items.scenario_name}</label>
												</li>
											);
										})}
									{/* <li className='mr-1'><input type="checkbox" id="checkboxFour" value="Moondancer" /><label for="checkboxFour">Moondancer</label></li> */}
								</ul>
							</Box>
							<Box>
								<Typography>Scenario Sequence</Typography>
								<ul
									className="ks-cboxtags"
									style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0 }}
								>
									{addcohort.scenarioSequence.map((scenarioId: any, index: number) => (
										<li
											key={index}
											draggable
											onDragStart={e => handleDragStart(e, index)}
											onDrop={e => handleDrop(e, index)}
											onDragOver={allowDrop}
											className="draggable-item"
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: '8px',
												padding: '8px',
												border: '1px solid #ccc',
												borderRadius: '4px',
												backgroundColor: '#f9f9f9',
												cursor: 'grab'
											}}
										>
											<span style={{ marginRight: '8px' }}>{index + 1}.</span>
											{allScenerio
												.filter((s: { scenario_id: number }) => s.scenario_id === scenarioId)
												.map((items: { scenario_name: string }) => items.scenario_name)}
										</li>
									))}
								</ul>
							</Box>
							<Box>
								<Typography>Passing Score</Typography>
								<TextField
									type="number"
									name="scenario_passing_score"
									required
									value={addcohort.scenario_passing_score}
									onChange={inputHandler}
									fullWidth
									inputProps={{
										min: 25,
										max: 100
									}}
								/>
							</Box>
							<Box>
								<Typography>Min. Passed Scenario</Typography>
								<TextField
									type="number"
									name="min_passed_scenario"
									required
									value={addcohort.min_passed_scenario}
									onChange={inputHandler}
									fullWidth
								/>
							</Box>
							<Box>
								<Typography>Maximum User</Typography>
								<TextField
									type="number"
									name="maxUser"
									required
									value={addcohort.maxUser}
									onChange={inputHandler}
									fullWidth
								/>
							</Box>
							<Box>
								<Button disabled={isDisabled} type="submit">
									Submit
								</Button>
							</Box>
						</FormControl>
					</form>
				</>
			)}
		</Box>
	);
};

export default EditCohort;
