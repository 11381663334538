import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	Avatar,
	Divider,
	IconButton,
	Grid,
	LinearProgress
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getUser from '../../../Helper/getUser';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

interface DiscussionDetailsProps {
	id?: any;
}

const DiscussionDetails: React.FC<DiscussionDetailsProps> = ({ id }) => {
	const [discussion, setDiscussion] = useState<any>(null);
	const [open, setOpen] = useState(false);
	const [reply, setReply] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingMain, setLoadingMain] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [parentReplyId, setParentReplyId] = useState<string | null>(null); // State to track parent reply ID
	const user = getUser();
	const { unhandleToken } = useHandleToken();
	const getDiscussions = async () => {
		try {
			setLoadingMain(true);
			const res = await axios.get(`${baseUrl}/api/admin/get-discussion-with-replies/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (res.data.success) {
				setDiscussion(res?.data?.discussion);
			}
			setLoadingMain(false);
		} catch (err) {
			console.error('Failed to fetch discussions:', err);
			setLoadingMain(false);
			unhandleToken(err);
		}
	};

	useEffect(() => {
		if (id) {
			getDiscussions();
		}
	}, [id]);

	const handleReplyClick = (parentReplyId: string | null = null) => {
		setParentReplyId(parentReplyId); // Set parent reply ID
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setReply('');
		setError(null); // Reset error on close
		setParentReplyId(null); // Reset parent reply ID on close
	};

	const handleSubmit = async () => {
		const payload = {
			discussion_id: discussion.id,
			reply,
			parent_reply_id: parentReplyId // Include parent reply ID if replying to another reply
		};

		setLoading(true);
		setError(null);

		try {
			const res = await axios.post(`${baseUrl}/api/admin/discussion/reply`, payload, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			if (res.data.success) {
				await getDiscussions();
				setReply('');
				setOpen(false);
			}
		} catch (e) {
			console.error('Error submitting reply:', e);
			setError('Failed to submit reply. Please try again.');
			unhandleToken(e);
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteReply = async (id: string) => {
		try {
			const res = await axios.delete(`${baseUrl}/api/admin/delete-discussion-reply/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (res.data.success) {
				await getDiscussions();
			}
		} catch (err) {
			console.error('Failed to delete discussion:', err);
			unhandleToken(err);
		}
	};

	return loadingMain ? (
		<LinearProgress />
	) : (
		<Box sx={{ padding: 2 }}>
			<Link
				style={{
					color: '#1F72C5',
					textDecoration: 'none'
				}}
				to="/discussions"
			>
				<Typography
					sx={{
						fontSize: '16pt',
						color: '#1F72C5',
						textAlign: 'left'
					}}
				>
					<ArrowBackIcon sx={{ mr: 2 }} />
					Back to discussion dashboard
				</Typography>
			</Link>

			{discussion && (
				<Card sx={{ margin: 'auto', border: '2px solid #65A3E2', borderRadius: 2, mt: 2 }}>
					<CardContent>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
							<Typography variant="subtitle2" color="text.secondary">
								Available from {moment(discussion.from).format('DD MMM, YY hh:mm A')} until{' '}
								{moment(discussion.until).format('DD MMM, YY hh:mm A')}
							</Typography>
							<Typography variant="subtitle2" color="text.secondary">
								Opened By{' '}
								{discussion?.openedBy?.firebase_id === user?.id
									? 'You'
									: `${discussion?.openedBy?.first_name} ${discussion?.openedBy?.last_name}`}
							</Typography>
						</div>
						<Typography variant="h5" sx={{ mt: 1 }}>
							{discussion.title || 'Module Title'}
						</Typography>
						<Typography
							variant="body2"
							sx={{ mt: 1, width: '100%', overflow: 'auto' }}
							dangerouslySetInnerHTML={{ __html: discussion.description || 'Description goes here' }}
						/>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
							<Button
								disabled={discussion?.isActive === false}
								variant="contained"
								sx={{ mt: 2, textTransform: 'none' }}
								color="primary"
								onClick={() => handleReplyClick()}
							>
								Reply
							</Button>
							<Typography variant="body2" sx={{ mt: 2, textAlign: 'right', width: '20%' }}>
								{discussion?.totalReplies || 0} Replies
							</Typography>
						</div>
					</CardContent>
				</Card>
			)}

			{/* Replies Section */}
			{discussion?.replies?.length > 0 && (
				<div>
					<List>
						{discussion.replies.map((reply: any, index: number) => (
							<div key={index}>
								<ListItem alignItems="flex-start" sx={{ padding: '20px 0' }}>
									<Grid container spacing={2}>
										<Grid item>
											<Avatar
												sx={{ width: 48, height: 48, mt: 1 }}
												alt={
													user?.id === reply?.user?.firebase_id
														? 'You'
														: `${reply.user.first_name} ${reply.user.last_name}`
												}
												src="path/to/avatar"
											/>
										</Grid>
										<Grid item xs>
											<ListItemText
												primary={
													<Box>
														<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
															{user?.id === reply?.user?.firebase_id
																? 'You'
																: `${reply.user.first_name} ${reply.user.last_name}`}
														</Typography>
														<Typography variant="body2" color="text.secondary">
															{moment(reply.createdAt).format('MMM D, h:mm A')}
														</Typography>
													</Box>
												}
												secondary={
													<Typography
														variant="body2"
														color="text.primary"
														sx={{ marginTop: 1 }}
														dangerouslySetInnerHTML={{ __html: reply.reply }}
													/>
												}
											/>
											<Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
												<Button
													disabled={discussion?.isActive === false}
													size="small"
													color="primary"
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'flex-end',
														gap: 0.5,
														textTransform: 'none'
													}}
													onClick={() => handleReplyClick(reply.id)} // Pass parent reply ID when replying to a reply
												>
													<ReplyIcon />
													<div style={{ fontSize: '14px' }}>Reply</div>
												</Button>
											</Box>
										</Grid>
										{user?.role === 'admin' && (
											<Grid item sx={{ mt: 1 }}>
												<DeleteIcon onClick={() => handleDeleteReply(reply.id)} sx={{ cursor: 'pointer' }} />
											</Grid>
										)}
									</Grid>
								</ListItem>

								{reply?.children?.length > 0
									? reply?.children.map((reply: any) => (
											<ListItem key={reply.id} sx={{ ml: 8, width: 'auto', mt: -1 }}>
												<Grid container spacing={2}>
													<Grid item>
														<Avatar
															sx={{ width: 48, height: 48 }}
															alt={
																user?.id === reply?.user?.firebase_id
																	? 'You'
																	: `${reply.user.first_name} ${reply.user.last_name}`
															}
															src="path/to/avatar"
														/>
													</Grid>
													<Grid item xs>
														<ListItemText
															primary={
																<Box>
																	<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
																		{user?.id === reply?.user?.firebase_id
																			? 'You'
																			: `${reply.user.first_name} ${reply.user.last_name}`}
																	</Typography>
																	<Typography variant="body2" color="text.secondary">
																		{moment(reply.createdAt).format('MMM D, h:mm A')}
																	</Typography>
																</Box>
															}
															secondary={
																<Typography
																	variant="body2"
																	color="text.primary"
																	dangerouslySetInnerHTML={{ __html: reply.reply }}
																/>
															}
														/>
														{/* <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
															<Button
																size="small"
																color="primary"
																onClick={() => handleReplyClick(reply.id)} // Pass parent reply ID when replying to a reply
															>
																Reply
															</Button>
														</Box> */}
													</Grid>
													{user?.role === 'admin' && (
														<Grid item sx={{ mt: 1 }}>
															<DeleteIcon
																onClick={() => handleDeleteReply(reply.id)}
																color="action"
																sx={{ cursor: 'pointer' }}
															/>
														</Grid>
													)}
												</Grid>
											</ListItem>
										))
									: ''}
								{index < discussion.replies.length - 1 && <Divider />}
							</div>
						))}
					</List>
				</div>
			)}

			{/* Reply Dialog */}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Submit your Reply</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="reply"
						label="Your Reply"
						type="text"
						fullWidth
						multiline
						rows={4}
						variant="filled"
						value={reply}
						onChange={e => setReply(e.target.value)}
					/>
					{error && (
						<Typography color="error" sx={{ mt: 1 }}>
							{error}
						</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading || !reply.trim()}>
						{loading ? <CircularProgress size={24} /> : 'Submit'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DiscussionDetails;
