import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import '../../Simulation.css';
import { Member } from '../../SimulatorPerson/Member';
import { Alert, Box, Button, Snackbar, Typography, Tabs, Tab, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	setRole,
	setSimulationValues,
	setSimulationStep,
	clearSelectedCompletedSimulationMessages,
	setSelectedCompletedSimulationMessages,
	clearLastConverstaion,
	clearConversationId,
	setLevel,
	setCohortSimulation,
	setSelectedCohort,
	clearSelectedCohort,
	setSelectedSimulation,
	clearSelectedSimulation
} from '../../../../Helper/Redux/Action/userAction';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../../Helper/GetToken';
import SimulationCategory from './SimulationCategory';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../config/firebase';
import getUser from '../../../../Helper/getUser';
import useBackToDefault from '../../../../Helper/helperFunction';
import SelectionStep from './SelectionStep';
import { ChatContext } from '../../../../Contexts';
import { getCurrentTimeStamp } from '../../../../Helper';
interface SimulationCreateProps {
	setActiveStep: (step: number) => void; // Adjust the type according to actual usage
}

interface Member {
	scenario_name: string;
	scenario_id?: string;
	description?: string;
	picture?: { data: string };
	childScenarios?: Member[];
	isPassed?: boolean;
	cohortId?: number;
}

const SimulationCreate: FunctionComponent<SimulationCreateProps> = ({ setActiveStep }) => {
	const { setChatLoading, chatLoading } = React.useContext(ChatContext);
	const { simulationId } = useSelector((state: any) => state?.selectedCompletedSimulation);

	const { roleName } = useSelector((state: any) => state?.role);
	const [isDisable, setIsDisable] = useState(false);
	// const [title, setTitle] = useState('');
	const [selectedId, setSelectedId] = useState('');
	const [members, setMembers] = useState<Member[]>([]);
	const [isCategorySelected, setIsCategorySelected] = useState(true);
	// const { category } = useSelector((state: any) => state?.roleCategory) || [];
	const [activeTab, setActiveTab] = useState(0);
	const dispatch = useDispatch();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(''); //for alert
	const [isTabScroll, setIsTabScroll] = useState(false);
	const scenarioRefs = useRef<(HTMLDivElement | null)[]>([]);
	let scrollTimeout: string | number | NodeJS.Timeout | undefined;
	const user = getUser();
	const backtoDefault = useBackToDefault();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		dispatch(clearSelectedCompletedSimulationMessages());
		backtoDefault();
		getScenarios();
	}, []);

	const [selectionStep, setSelectionStep] = useState(false);
	useEffect(() => {
		// this is condition taken for badges and work only with one cohort at a time.
		if (members.length > 0) {
			let level = 0;

			for (const item of members) {
				if (item.isPassed) {
					level += 1;
				} else {
					break; // Break the loop if an item is not passed
				}
			}
			// Assign the calculated level to your state or perform any other action
			dispatch(setLevel(level));
		}
	}, [members]);

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/cohortscenerio`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setMembers(res.data);
					dispatch(setCohortSimulation(res.data));
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const handleTabChange = (event: React.ChangeEvent<object>, newValue: number) => {
		// console.log(scenarioRefs.current[newValue]?.firstChild);
		setIsTabScroll(true);
		setActiveTab(newValue);
		const firstChildElement = scenarioRefs.current[newValue]?.firstChild as HTMLElement;
		if (firstChildElement) {
			firstChildElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
		}
	};

	const handleScroll = () => {
		// console.log(isTabScroll);

		if (!isTabScroll) {
			let minDistance = Infinity;
			let closestIndex = 0;
			scenarioRefs.current.forEach((ref, index) => {
				if (ref) {
					const distance = Math.abs(ref.getBoundingClientRect().left);
					if (distance < minDistance) {
						minDistance = distance;
						closestIndex = index;
					}
				}
			});
			setActiveTab(closestIndex);
		}

		// Clear the previous timeout and set a new one
		clearTimeout(scrollTimeout);
		scrollTimeout = setTimeout(() => {
			setIsTabScroll(false);
		}, 100); // Adjust the delay as needed
	};

	const handleMember = async (member: Member, index: number) => {
		try {
			// setTitle(member?.scenario_name || '');
			dispatch(setRole(member));
			dispatch(
				setSimulationValues({
					communication: 0,
					collaboration: 0,
					conflict_resolution: 0,
					interpersonal: 0
				})
			);
			setSelectedId(member?.scenario_id || '');
		} catch (err) {
			console.log(err);
		} finally {
			setIsDisable(false);
		}
	};
	const fetchLastMessage = async () => {
		const userDocRef = doc(db, 'simulation', user?.id);
		const userDocSnap = await getDoc(userDocRef);

		const currentSimulation = userDocSnap
			?.data()
			?.conversations?.find(
				(item: { scenarioId: string; analyticsFeedback: object }) =>
					item?.scenarioId === selectedId && item.analyticsFeedback
			);
		if (currentSimulation) {
			dispatch(setSelectedCompletedSimulationMessages(currentSimulation.messages, currentSimulation.conversationId));
		}
	};

	const handleSelection = async () => {
		try {
			setChatLoading(true);

			const userDocRefForPrepare = doc(db, 'simulationPrepare', user?.id);
			const userDocSnapForPrepare = await getDoc(userDocRefForPrepare);

			const preparations = userDocSnapForPrepare?.data();
			if (preparations && preparations?.conversations?.length > 0) {
				// const userDocRef = doc(db, 'simulation', user?.id);
				// const userDocSnap = await getDoc(userDocRef);

				// const simulations = userDocSnap?.data();
				// if (simulations && simulations?.conversations?.length > 0) {
				setSelectionStep(true);
				setChatLoading(false);
				// } else {
				// 	setChatLoading(true);
				// 	await handleNext(1);
				// 	firstInteractionFromCoach();
				// }
			} else {
				setChatLoading(true);
				await handleNext(1);
				firstInteractionFromCoach();
			}
		} catch (err) {
			setChatLoading(false);
		}
	};

	const handleNext = async (step = 1) => {
		dispatch(clearLastConverstaion());
		dispatch(clearConversationId());
		await fetchLastMessage()
			.then(() => {
				backtoDefault();
				setActiveStep(step);
				dispatch(setSimulationStep(step));
			})
			.catch(e => {
				console.error('Error fetching user simulation data:', e);
			});
	};

	const firstInteractionFromCoach = async () => {
		setChatLoading(true);

		const res = await axios.post(
			`${baseUrl}/msg-from-coach`,
			{
				simulationId,
				meetingTopic: roleName?.description
			},
			{
				headers: {
					Authorization: token
				}
			}
		);
		// setSummaryText(res?.data?.response);

		const messageFromCoach = {
			messageType: 'coach',
			message: res?.data?.response,
			time: getCurrentTimeStamp()
		};

		localStorage?.setItem('summaryForPreparation', JSON.stringify(messageFromCoach));
		setChatLoading(false);
	};

	return (
		<>
			{selectionStep ? (
				<SelectionStep
					setSelectionStep={setSelectionStep}
					handleNext={handleNext}
					firstInteractionFromCoach={firstInteractionFromCoach}
				/>
			) : (
				<div style={{ overflowX: 'hidden' }}>
					{isCategorySelected ? (
						<>
							<Box sx={{ textAlign: 'center', width: '100%', maxWidth: 'auto', padding: '20px' }}>
								<Box>
									<Typography
										variant="h6"
										sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: '700', fontSize: '28px' }}
									>
										Simulation
									</Typography>
									<Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
										Select any scenario to start a simulation.
									</Typography>
								</Box>
								<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
									<Tabs
										value={activeTab}
										onChange={handleTabChange}
										indicatorColor="primary"
										textColor="primary"
										variant="scrollable"
										scrollButtons
										allowScrollButtonsMobile
										sx={{
											maxWidth: '100%',
											'& .MuiTabs-flexContainer': {
												display: 'flex',
												justifyContent: 'start',
												// '@media (max-width: 1300px)': {
												// 	justifyContent: 'start'
												// },
												gap: '30px'
											},
											'& .MuiTabs-indicator': {
												display: 'none'
											},
											'& .MuiTab-root': {
												display: 'flex',
												flexShrink: 0,
												borderRadius: '8px',
												border: '1px solid #1C75BA',
												minHeight: '36px',
												minWidth: '80px',
												maxWidth: '100%',
												'&.Mui-selected': {
													backgroundColor: '#E5F3FE'
												}
											}
										}}
									>
										{members.map((member, index) => (
											<Tab label={member.scenario_name} key={index} />
										))}
									</Tabs>
								</Box>
								<Box
									sx={{
										marginTop: '20px',
										display: 'flex',
										overflowX: 'auto',
										width: '100%',
										gap: '45px',
										'&::-webkit-scrollbar': {
											width: '5px' /* For Chrome, Safari, and Opera */
										},
										'&::-webkit-scrollbar-track': {
											background: 'transparent' /* Makes the track background transparent */
										},
										'&::-webkit-scrollbar-thumb': {
											background: '#E5F3FE' /* Color of the scrollbar thumb */,
											border: '3px solid transparent' /* Adds space around the thumb */,
											borderRadius: '10px' /* Adds rounding to the thumb */
										},
										'&::-webkit-scrollbar-corner': {
											background: '#E5F3FE' /* Optional: Makes the corner background transparent */
										}
									}}
									onScroll={handleScroll}
								>
									{members.flatMap((parentScenario, parentIndex) => {
										return (
											<Box key={parentIndex} sx={{ marginBottom: '10px' }}>
												<Typography
													variant="h6"
													sx={{ fontWeight: '700', marginBottom: '16px', textAlign: 'left', whiteSpace: 'nowrap' }}
												>
													{parentScenario?.scenario_name}
												</Typography>
												{parentIndex === 0 ? (
													<Box
														sx={{ display: 'flex', gap: '45px' }}
														ref={(el: any) => {
															scenarioRefs.current[parentIndex] = el;
														}}
													>
														{parentScenario.childScenarios?.map((childScenario, childIndex) => (
															<Box
																key={`(${parentIndex}-${childIndex})*${Math.random()}`}
																sx={{
																	width: '300px',
																	borderRadius: '8px',
																	padding: '32px',
																	textAlign: 'start',
																	height: '420px',
																	display: 'flex',
																	flexDirection: 'column',
																	justifyContent: 'space-between',
																	border: '1px solid #4589FF',
																	background: roleName?.scenario_id === childScenario.scenario_id ? '#E5F3FE' : ''
																}}
															>
																<Box>
																	<Typography
																		sx={{
																			fontWeight: '700',
																			fontSize: '20px',
																			marginBottom: '15px',
																			minHeight: '60px',
																			display: 'flex',
																			gap: '10px',
																			alignItems: 'center'
																		}}
																	>
																		<img
																			alt="loading"
																			src={childScenario?.picture?.data}
																			style={{
																				width: '48px',
																				height: '48px',
																				marginRight: '10px'
																			}}
																		/>
																		{childScenario.scenario_name}
																	</Typography>
																	<Box>
																		<Typography
																			sx={{
																				fontSize: '16px',
																				fontFamily: 'IBM Plex Sans',
																				height: '200px',
																				overflowY: 'scroll',
																				scrollbarWidth: 'none' /* For Firefox */,
																				msOverflowStyle: 'none' /* For IE and Edge */,
																				'&::-webkit-scrollbar': {
																					width: '0px' /* For Chrome, Safari, and Opera */,
																					background: 'transparent'
																				}
																			}}
																		>
																			{childScenario.description}
																		</Typography>
																	</Box>
																</Box>
																<Box
																	sx={{
																		marginTop: '10px',
																		marginBottom: '10px',
																		width: '100%',
																		display: 'flex'
																	}}
																>
																	{roleName?.scenario_id === childScenario.scenario_id ? (
																		<Button
																			variant="contained"
																			sx={{
																				width: '100%',
																				padding: '8px 12px',
																				borderRadius: '100px',
																				fontFamily: 'Inter',
																				textTransform: 'capitalize',
																				fontSize: '18px',
																				background: '#1C75BA'
																			}}
																			disabled={isDisable}
																			onClick={() => {
																				// setTitle('');
																				// setSelectedId('');
																				dispatch(setRole(null));
																				dispatch(setSimulationValues(null));
																			}}
																		>
																			Selected
																		</Button>
																	) : (
																		<Button
																			sx={{
																				width: '100%',
																				padding: '8px 12px',
																				borderRadius: '100px',
																				fontFamily: 'Inter',
																				textTransform: 'capitalize',
																				fontSize: '18px',
																				background: '#1C75BA'
																			}}
																			variant="contained"
																			disabled={isDisable}
																			onClick={() => {
																				handleMember(childScenario, childIndex);
																			}}
																		>
																			Select
																		</Button>
																	)}
																</Box>
															</Box>
														))}
													</Box>
												) : (
													<Box
														sx={{ display: 'flex', gap: '45px' }}
														ref={(el: any) => {
															scenarioRefs.current[parentIndex] = el;
														}}
													>
														{parentScenario.childScenarios?.map((childScenario, childIndex) =>
															members[parentIndex - 1].isPassed || members[parentIndex].isPassed ? (
																<Box
																	key={`(${parentIndex}-${childIndex})*${Math.random()}`}
																	sx={{
																		width: '300px',
																		borderRadius: '8px',
																		padding: '32px',
																		textAlign: 'start',
																		height: '420px',
																		display: 'flex',
																		flexDirection: 'column',
																		justifyContent: 'space-between',
																		border: '1px solid #4589FF',
																		background: roleName?.scenario_id === childScenario.scenario_id ? '#E5F3FE' : ''
																	}}
																>
																	<Box>
																		<Typography
																			sx={{
																				fontWeight: '700',
																				fontSize: '20px',
																				marginBottom: '15px',
																				minHeight: '60px',
																				display: 'flex',
																				gap: '10px',
																				alignItems: 'center'
																			}}
																		>
																			<img
																				alt="loading"
																				src={childScenario?.picture?.data}
																				style={{
																					width: '48px',
																					height: '48px',
																					marginRight: '10px'
																				}}
																			/>
																			{childScenario.scenario_name}
																		</Typography>
																		<Box>
																			<Typography
																				sx={{
																					fontSize: '16px',
																					fontFamily: 'IBM Plex Sans',
																					height: '200px',
																					overflowY: 'scroll',
																					scrollbarWidth: 'none' /* For Firefox */,
																					msOverflowStyle: 'none' /* For IE and Edge */,
																					'&::-webkit-scrollbar': {
																						width: '0px' /* For Chrome, Safari, and Opera */,
																						background: 'transparent'
																					}
																				}}
																			>
																				{childScenario.description}
																			</Typography>
																		</Box>
																	</Box>
																	<Box
																		sx={{
																			marginTop: '10px',
																			marginBottom: '10px',
																			width: '100%',
																			display: 'flex'
																		}}
																	>
																		{roleName?.scenario_id === childScenario.scenario_id ? (
																			<Button
																				variant="contained"
																				sx={{
																					width: '100%',
																					padding: '8px 12px',
																					borderRadius: '100px',
																					fontFamily: 'Inter',
																					textTransform: 'capitalize',
																					fontSize: '18px',
																					background: '#1C75BA'
																				}}
																				disabled={isDisable}
																				onClick={() => {
																					// setTitle('');
																					// setSelectedId('');
																					dispatch(setRole(null));
																					dispatch(setSimulationValues(null));
																				}}
																			>
																				Selected
																			</Button>
																		) : (
																			<Button
																				sx={{
																					width: '100%',
																					padding: '8px 12px',
																					borderRadius: '100px',
																					fontFamily: 'Inter',
																					textTransform: 'capitalize',
																					fontSize: '18px',
																					background: '#1C75BA'
																				}}
																				variant="contained"
																				disabled={isDisable}
																				onClick={() => {
																					handleMember(childScenario, childIndex);
																				}}
																			>
																				Select
																			</Button>
																		)}
																	</Box>
																</Box>
															) : (
																<Box
																	key={`(${parentIndex}-${childIndex})*${Math.random()}`}
																	sx={{
																		width: '300px',
																		borderRadius: '8px',
																		padding: '32px',
																		textAlign: 'start',
																		height: '420px',
																		display: 'flex',
																		flexDirection: 'column',
																		justifyContent: 'space-between',
																		border: '1px solid #4589FF',
																		background: '#E5F3FE'
																	}}
																>
																	<Typography
																		sx={{
																			background: '#161616',
																			color: 'white',
																			fontSize: '20px',
																			padding: '8px 12px',
																			borderRadius: '100px',
																			textAlign: 'center',
																			margin: 'auto 0'
																		}}
																	>
																		Locked
																	</Typography>
																</Box>
															)
														)}
													</Box>
												)}
											</Box>
										);
									})}
								</Box>
							</Box>
							{members?.length > 0 && (
								<Box
									sx={{
										width: '100%',
										marginBottom: '10px',
										display: 'flex',
										justifyContent: 'center',
										'@media (max-width: 768px)': {
											px: '10px'
										}
									}}
								>
									<Button
										disabled={!roleName || chatLoading}
										variant="outlined"
										sx={{ ...styles.Button }}
										onClick={handleSelection}
									>
										Next {chatLoading && <CircularProgress sx={{ ml: '10px' }} size={16} />}
									</Button>
								</Box>
							)}

							<Snackbar
								open={open}
								autoHideDuration={3000}
								onClose={() => setOpen(false)}
								sx={{ padding: '10px 5px' }}
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							>
								<Alert
									onClose={() => setOpen(false)}
									severity="warning"
									variant="outlined"
									sx={{
										width: '100%',
										padding: '10px 5px',
										border: '1px solid rgba(5, 10, 58, 0.3)',
										borderRadius: '5px',
										background: 'white'
									}}
								>
									{message}
								</Alert>
							</Snackbar>
						</>
					) : (
						<SimulationCategory setIsCategorySelected={setIsCategorySelected} />
					)}
				</div>
			)}
		</>
	);
};

export default SimulationCreate;

const styles = {
	Button: {
		borderRadius: '100px',
		border: '1px solid #1C75BA',
		p: '10px 90px',
		fontSize: '18px',
		fontWeight: 500,
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};
