import React, { useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	TablePagination,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	Rating,
	Snackbar,
	Alert
} from '@mui/material';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Feedback {
	id: number;
	stage: string;
	user: {
		firebase_id: string;
		name: string;
		last_name: string;
		first_name: string;
	};
	scenario: {
		id: number;
		scenario_name: string;
	};
	child_scenario: {
		id: number | null;
		scenario_name: string | null;
	};
	created_at: string;
	technical_point: number;
	content_point: number;
	userExperience_point: number;
	feedback: string;
}

const FeedbackTable: React.FC = () => {
	const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);
	const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();

	const fetchFeedbacks = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${baseUrl}/feedback/getAll`, {
				params: { page, limit },
				headers: {
					Authorization: token
				}
			});
			setFeedbacks(response.data.data);
			setTotal(response.data.pagination.total);
		} catch (error) {
			console.error('Error fetching feedback:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchFeedbacks();
	}, [page, limit]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1); // Reset to the first page when changing rows per page
	};

	// Open modal and set selected feedback text
	const handleOpenModal = (feedbackText: string) => {
		setSelectedFeedback(feedbackText);
		setOpenModal(true);
	};

	// Close modal
	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedFeedback(null);
	};

	const deleteHandler = async (id: number) => {
		setLoading(true);
		try {
			await axios.delete(`${baseUrl}/feedback/delete/${id}`, {
				headers: {
					Authorization: token
				}
			});
			setSnackbar({ open: true, message: 'User feedback deleted successfully', severity: 'success' });
			fetchFeedbacks(); // Refresh the list after deletion
		} catch (error) {
			console.error('Error deleting feedback:', error);
			setSnackbar({ open: true, message: 'Failed to delete user feedback', severity: 'error' });
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Box
				sx={{
					width: '100%',
					padding: '24px',
					display: 'flex',
					flexDirection: 'column',
					gap: '32px',
					fontFamily: 'IBM Plex Sans'
				}}
			>
				<Typography variant="h5" gutterBottom sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>
					User Feedback
				</Typography>

				{loading ? (
					<CircularProgress />
				) : (
					<TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
						<Table aria-label="user feedback table">
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow>
									<TableCell>User</TableCell>
									<TableCell>Stage</TableCell>
									<TableCell>Scenario</TableCell>
									<TableCell>Child Scenario</TableCell>
									<TableCell>Technical Point</TableCell>
									<TableCell>Content Point</TableCell>
									<TableCell>User Experience</TableCell>
									<TableCell>Feedback</TableCell>
									<TableCell>Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{feedbacks.map((feedback, index) => (
									<TableRow key={index} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
										<TableCell>{`${feedback.user.first_name} ${feedback.user.last_name}`}</TableCell>
										<TableCell>{feedback.stage}</TableCell>
										<TableCell align="left">{feedback.scenario.scenario_name}</TableCell>
										<TableCell align="left">{feedback.child_scenario?.scenario_name || 'N/A'}</TableCell>
										<TableCell align="left">
											<Rating value={feedback.technical_point} readOnly />
										</TableCell>
										<TableCell align="left">
											<Rating value={feedback.content_point} readOnly />
										</TableCell>
										<TableCell align="left">
											<Rating value={feedback.userExperience_point} readOnly />
										</TableCell>
										<TableCell align="left">
											{feedback.feedback.length > 10 ? (
												<>
													{feedback.feedback.slice(0, 10)}...
													<Button variant="text" color="primary" onClick={() => handleOpenModal(feedback.feedback)}>
														Read More
													</Button>
												</>
											) : (
												feedback.feedback
											)}
										</TableCell>
										<TableCell>
											<DeleteOutlineIcon sx={{ cursor: 'pointer' }} onClick={() => deleteHandler(feedback.id)} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={total}
							page={page - 1}
							onPageChange={handleChangePage}
							rowsPerPage={limit}
							onRowsPerPageChange={handleChangeRowsPerPage}
							rowsPerPageOptions={[5, 10, 20]}
						/>
					</TableContainer>
				)}

				<Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
					<DialogTitle>Feedback Details</DialogTitle>
					<DialogContent dividers>
						<Typography variant="body1">{selectedFeedback}</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseModal} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>

				{/* Snackbar for Toast Messages */}
				<Snackbar
					open={snackbar.open}
					autoHideDuration={6000}
					onClose={() => setSnackbar({ ...snackbar, open: false })}
				>
					<Alert
						onClose={() => setSnackbar({ ...snackbar, open: false })}
						severity={snackbar.severity as any}
						sx={{ width: '100%' }}
					>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</Box>
		</>
	);
};

export default FeedbackTable;
