import {
	Box,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

const ActivityByCategory = () => {
	const [allCategories, setAllCategories] = useState([]);
	const [usersData, setUsersData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState<{ scenario_id: number | null }>();
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getScenarios();
	}, []);

	useEffect(() => {
		if (selectedCategory) {
			getUserActivity();
		}
	}, [selectedCategory]);

	const getUserActivity = async () => {
		if (selectedCategory)
			await axios
				.get(`${baseUrl}/api/admin/userActivityByCategory/${selectedCategory.scenario_id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data) {
						setUsersData(res.data);
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
	};

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setAllCategories(res.data);

					if (isInitialLoad) {
						setSelectedCategory(res.data[0]);
						setIsInitialLoad(!isInitialLoad);
					}
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	return (
		<Box
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '32px',
				fontFamily: 'IBM Plex Sans',
				textAlign: 'center'
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box>
					<Typography sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>Select category</Typography>
				</Box>
				<Box sx={{ display: 'flex', gap: '10px' }}>
					<Box>
						<Select
							name="selectedRole"
							value={JSON.stringify(selectedCategory) || ''}
							fullWidth
							onChange={(e: SelectChangeEvent<string>) => {
								setSelectedCategory(JSON.parse(e.target.value));
							}}
						>
							{allCategories.map((item: { scenario_name: string }, index: number) => (
								<MenuItem key={index} value={JSON.stringify(item)}>
									<Typography>{item.scenario_name}</Typography>
								</MenuItem>
							))}
						</Select>
					</Box>
				</Box>
			</Box>
			<Box>
				{Array.isArray(usersData) && usersData.length > 0 ? (
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow sx={{ textWrap: 'nowrap' }}>
									<TableCell align="left">User Name</TableCell>
									<TableCell align="left">Names of Coaches</TableCell>
									<TableCell align="left">Cohort Name</TableCell>
									<TableCell align="left">Number of Sessions</TableCell>
									<TableCell align="left">Avg Score</TableCell>
									<TableCell align="left">Delta</TableCell>
									<TableCell align="left">Peak Score</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{usersData.map(
									(
										user: {
											userName: string;
											coachNames: string;
											cohortName: string;
											numberOfSessions: number;
											avgScore: number;
											delta: number;
											highestScore: number;
										},
										index: number
									) => {
										return (
											<>
												<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
													<TableCell align="left">{user?.userName}</TableCell>
													<TableCell align="left">{user?.coachNames}</TableCell>
													<TableCell align="left">{user?.cohortName}</TableCell>
													<TableCell align="left">{user?.numberOfSessions}</TableCell>
													<TableCell align="left">
														{user?.numberOfSessions > 0 ? Math?.ceil((100 * (user?.avgScore + 1)) / 4) : user?.avgScore}
													</TableCell>
													<TableCell align="left">
														{user?.numberOfSessions > 0 ? Math?.ceil((100 * (user?.delta + 1)) / 4) : user?.delta}
													</TableCell>
													<TableCell align="left">
														{user?.highestScore > 0
															? Math?.ceil((100 * (user?.highestScore + 1)) / 4)
															: user?.highestScore}
													</TableCell>
												</TableRow>
											</>
										);
									}
								)}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Typography variant="h6">This category is empty</Typography>
				)}
			</Box>
		</Box>
	);
};

export default ActivityByCategory;
