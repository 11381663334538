import React from 'react';
import { Box, Typography } from '@mui/material';

import { useSelector } from 'react-redux';

const SimulationHeader = () => {
	const { roleName } = useSelector((state: any) => state?.role);
	return (
		<>
			<Box sx={styles.Box}>
				{/* <Typography sx={styles.Title}>{title}</Typography>
        <Typography sx={styles.Para}>{para}</Typography> */}
				<Typography sx={styles.Title}>Simulation Scenario: {roleName?.scenario_name}</Typography>
				{/* <Typography sx={styles.Title}>{roleName?.name}</Typography> */}
				<Typography sx={styles.Para}>
					{roleName?.description?.split('\n').map((line: string, index: number) => (
						<React.Fragment key={index}>
							You are in the simulation phase. {line}
							<br />
						</React.Fragment>
					))}
				</Typography>
			</Box>
		</>
	);
};

const styles = {
	Box: {
		// maxWidth: '70%',
		'@media (max-width: 1024px)': {
			maxWidth: '90%'
		}
	},
	Title: {
		color: '#161616',
		fontFamily: 'IBM Plex Sans',
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: '36px',
		pt: '15px',
		'@media (max-width: 1600px)': {
			fontSize: '22px',
			lineHeight: 'normal'
		}
	},
	Para: {
		color: '#161616',
		fontFamily: 'Outfit',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		pt: '15px  ',
		'@media (max-width: 1600px)': {
			fontSize: '15px',
			lineHeight: 'normal'
		},
		height: '85px',
		overflowY: 'auto'
	}
};
export default SimulationHeader;
