import React from 'react';
import { AppRoutes } from './AppRoutes';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import './Assets/Css/fonts.css';
import './Assets/Css/common.css';

import { Provider } from 'react-redux';
import { Persistor, Store } from './Helper/Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { SSEProvider } from 'react-hooks-sse';
import { SSEContextProvider, ChatContextProvider } from './Contexts';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Provider store={Store}>
					<SSEProvider endpoint={`${process.env.REACT_APP_BACKEND_BASE_URL}/ask`}>
						<SSEContextProvider>
							<PersistGate loading={null} persistor={Persistor}>
								<ChatContextProvider>
									<AppRoutes />
								</ChatContextProvider>
							</PersistGate>
						</SSEContextProvider>
					</SSEProvider>
				</Provider>
			</BrowserRouter>
		</div>
	);
};

export default App;
