import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import DiscussionList from './DiscussionList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

const PublicDiscussion: React.FC = () => {
	const navigate = useNavigate();
	const [activeDiscussions, setActiveDiscussions] = React.useState([]);
	const [draftDiscussions, setDraftDiscussions] = React.useState([]);
	const [closedDiscussions, setClosedDiscussions] = React.useState([]);
	const [loading, setLoading] = useState(false);
	const { unhandleToken } = useHandleToken();
	const getDiscussions = async () => {
		setLoading(true);

		try {
			const discussions = await axios.get(`${baseUrl}/api/admin/get-active-public-discussions`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			if (discussions.data.success) {
				setActiveDiscussions(discussions.data.activeDiscussions);
				setDraftDiscussions(discussions.data.draftDiscussions);
				setClosedDiscussions(discussions.data.closedDiscussions);
			}
		} catch (error) {
			console.error('Error fetching discussions:', error);
			unhandleToken(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getDiscussions();
	}, []);

	return loading ? (
		<LinearProgress />
	) : (
		<Box sx={{ padding: 2 }}>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					navigate('/discussions?tab=new');
				}}
				sx={{ marginBottom: 2 }}
			>
				<AddCircleOutlineIcon sx={{ mr: '12px' }} /> Discussion
			</Button>

			{draftDiscussions?.length > 0 && (
				<>
					<Typography variant="body1" sx={{ fontWeight: 600, marginBottom: 1 }}>
						Your Draft discussions
					</Typography>
					<Box sx={{ marginBottom: 4, minHeight: '100px' }}>
						{draftDiscussions.map((discussion, index) => (
							<DiscussionList key={index} discussion={discussion} type="draft" getDiscussions={getDiscussions} />
						))}
					</Box>
				</>
			)}

			<Typography variant="body1" sx={{ fontWeight: 600, marginBottom: 1 }}>
				Active discussions
			</Typography>
			<Box sx={{ marginBottom: 4, minHeight: '100px' }}>
				{activeDiscussions?.length <= 0
					? 'No Active Discussions'
					: activeDiscussions.map((discussion, index) => (
							<DiscussionList key={index} discussion={discussion} type="active" getDiscussions={getDiscussions} />
						))}
			</Box>

			{closedDiscussions?.length > 0 && (
				<>
					<Typography variant="body1" sx={{ fontWeight: 600, marginBottom: 1 }}>
						Closed discussions
					</Typography>
					<Box sx={{ marginBottom: 4, minHeight: '100px' }}>
						{closedDiscussions.map((discussion, index) => (
							<DiscussionList key={index} discussion={discussion} type="closed" getDiscussions={getDiscussions} />
						))}
					</Box>
				</>
			)}
		</Box>
	);
};

export default PublicDiscussion;
