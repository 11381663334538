import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TokenError = () => {
	const [countdown, setCountdown] = useState(5); // Start countdown from 5
	const navigate = useNavigate();

	useEffect(() => {
		// Countdown logic
		const timer = setInterval(() => {
			setCountdown(prev => prev - 1);
		}, 1000);

		// Redirect after 5 seconds
		const redirect = setTimeout(() => {
			navigate('/');
		}, 5000);

		// Cleanup timers on component unmount
		return () => {
			clearInterval(timer);
			clearTimeout(redirect);
		};
	}, [navigate]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					bgcolor: 'background.default',
					p: 3
				}}
			>
				<CircularProgress sx={{ mb: 3 }} />
				<Typography variant="h5" sx={{ mb: 2 }}>
					You have been logged out.
				</Typography>
				<Typography variant="body1" sx={{ mb: 4 }}>
					Redirecting to the home page in {countdown} second{countdown !== 1 ? 's' : ''}.
				</Typography>
				<Button variant="contained" color="primary" onClick={() => navigate('/')}>
					Go to Home Now
				</Button>
			</Box>
		</>
	);
};

export default TokenError;
