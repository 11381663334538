import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import mike from '../Assets/Images/keyboard_voice.svg';
import PropTypes from 'prop-types';
import { getToken, useHandleToken } from './GetToken';
import { ChatContext } from '../Contexts';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { VoiceModalType } from '../types/voiceModal';
import { IValueHandler } from '../types/valueHandler';

interface AudioRecorderProps {
	valueHandler: (data: IValueHandler) => void;
	onTimerUpdate: (time: any) => void;
	createNewConversation?: () => Promise<boolean>;
	setIsMessageAddedTofalse?: (userMessage: string, assistantMessage: string, isTheLastMessage: boolean) => void;
	prompt?: string;
	voiceModal?: VoiceModalType;
}

interface RealtimeEvent {
	time: string;
	source: 'client' | 'server';
	count?: number;
	event: { [key: string]: any };
}

const isValidVoiceModal = (value: VoiceModalType) => {
	const validVoices = ['alloy', 'echo', 'shimmer', undefined];
	return validVoices.includes(value);
};

const AudioRecorder: React.FC<AudioRecorderProps> = ({
	valueHandler,
	onTimerUpdate,
	createNewConversation,
	setIsMessageAddedTofalse,
	prompt,
	voiceModal
}) => {
	const RELAY_SERVER_URL = process.env.REACT_APP_RELAY_SERVER_ENPOINT;
	const { setMessageType, setIsMessageAddedToDb, isConnected, setIsConnected } = useContext(ChatContext);

	const instructions = `System settings:
		Instructions:
		${
			prompt
				? prompt
				: `- You are an artificial intelligence agent responsible for helping test realtime voice capabilities
		- Please make sure to respond with a helpful voice via audio
		- Be kind, helpful, and curteous
		- It is okay to ask the user questions
		- Be open to exploration and conversation`
		}
	`;

	const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
	const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
	const clientRef = useRef<RealtimeClient>(new RealtimeClient({ url: RELAY_SERVER_URL }));
	const [isRecording, setIsRecording] = useState<boolean>();
	const [newFormattedTime, setNewFormattedTime] = useState<string>('00:00');

	const timerRef = useRef<number | null>(null);
	const [elapsedTime, setElapsedTime] = useState<number>(0);
	const formatTime = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
	};

	const startTimeRef = useRef<string>(new Date().toISOString());

	const connectConversation = useCallback(async () => {
		try {
			setMessageType('audio');
			let result = false;
			if (createNewConversation) {
				result = await createNewConversation();
			}

			if (result) {
				const client = clientRef.current;
				const wavRecorder = wavRecorderRef.current;
				const wavStreamPlayer = wavStreamPlayerRef.current;

				// Set state variables
				startTimeRef.current = new Date().toISOString();
				setIsConnected(true);

				// Connect to microphone
				await wavRecorder?.begin();

				// Connect to audio output
				await wavStreamPlayer?.connect();

				// Connect to realtime API
				await client?.connect();

				if (client.getTurnDetectionType() === 'server_vad') {
					await wavRecorder.record(data => client.appendInputAudio(data.mono));
				}
				setElapsedTime(0);
				setIsRecording(true);
				timerRef.current = window.setInterval(() => {
					setElapsedTime(prev => {
						const newTime = prev + 1;
						setNewFormattedTime(formatTime(newTime));
						return newTime;
					});
				}, 1000);
			}
		} catch (error) {
			console.error('Error connecting to conversation:', error);
			setIsConnected(false);
			setIsRecording(false);
		}
	}, []);

	useEffect(() => {
		if (elapsedTime > 0) {
			onTimerUpdate(elapsedTime);
		}
	}, [elapsedTime, onTimerUpdate]);

	let userMessage = '';
	let assistantMessage = '';
	let prevMessage = '';

	const disconnectConversation = useCallback(async () => {
		try {
			if (setIsMessageAddedTofalse) {
				setIsMessageAddedTofalse(userMessage, assistantMessage, true);
				setIsMessageAddedToDb(false);
				userMessage = '';
				assistantMessage = '';
			}
			setMessageType('text');
			setIsConnected(false);
			setIsRecording(false);

			const client = clientRef.current;
			client?.disconnect();

			const wavRecorder = wavRecorderRef.current;
			await wavRecorder?.end();

			const wavStreamPlayer = wavStreamPlayerRef.current;
			await wavStreamPlayer?.interrupt();

			if (timerRef.current) {
				clearInterval(timerRef.current);
				timerRef.current = null;
			}

			setElapsedTime(0);
			setNewFormattedTime('00:00');
		} catch (error) {
			console.error('Error disconnecting from conversation:', error);
			setIsConnected(false);
		}
	}, []);

	useEffect(() => {
		// Get refs
		const wavStreamPlayer = wavStreamPlayerRef.current;
		const client = clientRef.current;

		// Set instructions
		client.updateSession({ instructions: instructions });
		client.updateSession({ voice: isValidVoiceModal(voiceModal) ? voiceModal : 'alloy' });
		// Set transcription, otherwise we don't get user transcriptions back
		client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

		// handle realtime events from client + server for event logging
		client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
			if (realtimeEvent.event.type === 'input_audio_buffer.speech_started') {
				if (setIsMessageAddedTofalse) {
					setIsMessageAddedTofalse(userMessage, assistantMessage, false);
					userMessage = '';
					assistantMessage = '';
				}
			}

			if (realtimeEvent.event.type === 'session.created') {
				setIsRecording(true);
			}
		});
		client.on('error', (event: any) => console.error(event));
		client.on('conversation.interrupted', async () => {
			const trackSampleOffset = await wavStreamPlayer.interrupt();
			if (trackSampleOffset?.trackId) {
				const { trackId, offset } = trackSampleOffset;
				await client.cancelResponse(trackId, offset);
			}
		});
		client.on('conversation.updated', async ({ item, delta }: any) => {
			if (delta?.audio) {
				wavStreamPlayer.add16BitPCM(delta.audio, item.id);
			}
			if (item.status === 'completed' && item.formatted.audio?.length) {
				const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
				item.formatted.file = wavFile;
			}

			if (
				item.type !== 'function_call' &&
				item.content[0] &&
				item.content[0].transcript !== '' &&
				item.content[0].transcript !== null &&
				item.content[0].transcript !== prevMessage
			) {
				valueHandler({ message: item.content[0].transcript, role: item.role === 'user' ? 'client' : 'coach' });
				if (item.role === 'user') userMessage = item.content[0].transcript;
				if (item.role === 'assistant') assistantMessage = item.content[0].transcript;
				prevMessage = item.content[0].transcript;
			}
		});

		return () => {
			// cleanup; resets to defaults
			client.reset();
		};
	}, []);

	const changeTurnEndType = async (value: string) => {
		const client = clientRef.current;
		const wavRecorder = wavRecorderRef.current;
		if (value === 'none' && wavRecorder.getStatus() === 'recording') {
			await wavRecorder.pause();
		}
		client.updateSession({
			turn_detection: value === 'none' ? null : { type: 'server_vad' }
		});
		if (value === 'server_vad' && client.isConnected()) {
			await wavRecorder.record(data => client.appendInputAudio(data.mono));
		}
	};

	useEffect(() => {
		changeTurnEndType('server_vad');
	}, []);

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, []);

	return (
		<div className="micBox">
			{isRecording ? <p className="micTitle-primary">{newFormattedTime}</p> : ''}
			<button className="btn circle-btn" onClick={isConnected ? disconnectConversation : connectConversation}>
				<div className={`${isRecording ? 'micImage micAnimation' : 'micImage'}`}>
					<img src={mike} alt="mike" className={isRecording ? 'recordingAnimation' : ''} />
				</div>
			</button>
		</div>
	);
};

AudioRecorder.propTypes = {
	valueHandler: PropTypes.func.isRequired,
	onTimerUpdate: PropTypes.func.isRequired
};

export default AudioRecorder;
