import React, { lazy } from 'react';
import progressIcon from '../../Assets/Images/progressIcon.svg';
import capaIcon from '../../Assets/Images/capa.svg';
import settingIcon from '../../Assets/Images/setting.svg';
import communityIcon from '../../Assets/Images/supportive-community-icon-vector.svg';
import simulationIcon from '../../Assets/Images/simulation.svg';
import ourMeetingIcon from '../../Assets/Images/ourMeetingIcon.svg';
import playgroundIcon from '../../Assets/Images/playground-icon.svg';
import scenarioIcon from '../../Assets/Images/scenario-folder-icon.svg';
import DiscussionIcon from '../../Assets/Images/Discussion.svg';
import Progress from '../Progress/Progress';
import CoachDashboard from '../CoachDashboard/CoachDashboard';
import Simulation from '../Simulation/Simulation';
import Setting from '../Setting/Setting';
import AnalyzeMeetings from '../AnalyzeMeetings/AnalyzeMeetings';
import ChatBox from '../Chat/ChatBox';
import CategoryTable from '../Admin/Simulation/CategoryTable';
import CoachStudent from '../Admin/Coach/CoachStudent';
import Users from '../Admin/Coach/Users';
import CohortTable from '../Admin/Cohort/CohortTable';
import UserActivity from '../Admin/UserActivity/UserActivity';
import { CompanyTable } from '../Admin/Company/CompanyTable';
import AssignedCategory from '../Coach/Simulation/AssignedCategory';
import MainPrompt from '../Admin/Scenario/MainPrompt';
import Community from '../Community/Community';
import UserInstructions from '../UserInstructions';
import Playground from '../Admin/Playground';
import { DiscussionMain } from '../Admin/Discussion';
import CohortUser from '../Coach/UserManagement/CohortUser';

const Dashboard = lazy(() => import('../../Pages/Dashboard/Dashboard'));

export const clientRouteConfig = [
	{
		path: '/simulation',
		name: 'Simulation',
		component: <Simulation />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/progress',
		name: 'Progress',
		component: <Progress />,
		layout: '/dashboard',
		icon: progressIcon,
		sideDrawer: true
	},
	{
		path: '/session',
		name: 'Coach',
		component: <Dashboard />,
		layout: '/dashboard',
		icon: capaIcon,
		sideDrawer: true
	},
	{
		path: '/analyze-meetings',
		name: 'Analyze Meetings',
		component: <AnalyzeMeetings />,
		layout: '/dashboard',
		icon: ourMeetingIcon,
		sideDrawer: true
	},
	{
		path: '/community',
		name: 'Community & Resource',
		component: <Community />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: true
	},
	{
		path: '/discussions',
		name: 'Discussions',
		component: <DiscussionMain />,
		layout: '/dashboard',
		icon: DiscussionIcon,
		sideDrawer: true
	},
	// {
	// 	path: '/setting',
	// 	name: 'Setting',
	// 	component: <Setting />,
	// 	layout: '/dashboard',
	// 	icon: settingIcon,
	// 	sideDrawer: true
	// },
	{
		path: '/user-instruction',
		name: 'User Instructions',
		component: <UserInstructions />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: false
	}
];
export const coachRouteConfig = [
	{
		path: '/Dashboard',
		name: 'Dashboard',
		component: <CoachDashboard />,
		layout: '/dashboard',
		icon: progressIcon,
		sideDrawer: true
	},
	{
		path: '/Assigned-Simulation',
		name: 'Simulation',
		component: <AssignedCategory />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/progress',
		name: 'Progress',
		component: <Progress />,
		layout: '/dashboard',
		icon: progressIcon,
		sideDrawer: false
	},
	{
		path: '/session',
		name: 'Session',
		component: <ChatBox />,
		layout: '/dashboard',
		icon: capaIcon,
		sideDrawer: false
	},
	{
		path: '/community',
		name: 'Community & Resource',
		component: <Community />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: true
	},
	{
		path: '/discussions',
		name: 'Discussions',
		component: <DiscussionMain />,
		layout: '/dashboard',
		icon: DiscussionIcon,
		sideDrawer: true
	},
	{
		path: '/user-management',
		name: 'User Management',
		component: <CohortUser />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/setting',
		name: 'Setting',
		component: <Setting />,
		layout: '/dashboard',
		icon: settingIcon,
		sideDrawer: true
	},
	{
		path: '/simulation',
		name: 'Simulation',
		component: <Simulation />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: false
	},

	{
		path: '/user-instruction',
		name: 'User Instructions',
		component: <UserInstructions />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: false
	}
];

export const AdminRouteConfig = [
	{
		path: '/company',
		name: 'Company',
		component: <CompanyTable />,
		layout: '/dashboard',
		icon: capaIcon,
		sideDrawer: true
	},
	{
		path: '/progress',
		name: 'Progress',
		component: <Progress />,
		layout: '/dashboard',
		icon: progressIcon,
		sideDrawer: false
	},
	{
		path: '/cohort',
		name: 'Cohort',
		component: <CohortTable />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/cohort-user',
		name: 'Cohort User Management',
		component: <CoachStudent />,
		layout: '/dashboard',
		icon: ourMeetingIcon,
		sideDrawer: true
	},
	{
		path: '/scenario',
		name: 'Scenario',
		component: <MainPrompt />,
		layout: '/dashboard',
		icon: scenarioIcon,
		sideDrawer: true
	},
	{
		path: '/simulations',
		name: 'Simulation',
		component: <CategoryTable />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/simulation',
		name: 'Simulation',
		component: <Simulation />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: false
	},
	{
		path: '/Users',
		name: 'Users',
		component: <Users />,
		layout: '/dashboard',
		icon: settingIcon,
		sideDrawer: true
	},
	{
		path: '/User-Activity',
		name: 'User Activity',
		component: <UserActivity />,
		layout: '/dashboard',
		icon: progressIcon,
		sideDrawer: true
	},
	{
		path: '/discussions',
		name: 'Discussions',
		component: <DiscussionMain />,
		layout: '/dashboard',
		icon: DiscussionIcon,
		sideDrawer: true
	},
	{
		path: '/playground',
		name: 'Playground',
		component: <Playground />,
		layout: '/dashboard',
		icon: playgroundIcon,
		sideDrawer: true
	},
	{
		path: '/user-instruction',
		name: 'User Instructions',
		component: <UserInstructions />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: false
	}
];
export const editorRouteConfig = [
	{
		path: '/scenario',
		name: 'Scenario',
		component: <MainPrompt />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/simulations',
		name: 'Simulation',
		component: <CategoryTable />,
		layout: '/dashboard',
		icon: simulationIcon,
		sideDrawer: true
	},
	{
		path: '/user-instruction',
		name: 'User Instructions',
		component: <UserInstructions />,
		layout: '/dashboard',
		icon: communityIcon,
		sideDrawer: false
	}
];
