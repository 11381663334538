import React from 'react';
import ActivityByCategory from './ActivityByCategory';
import UsersSummary from './UsersSummary';
import FeedbackTable from './FeedbackTable';

const UserActivity = () => {
	return (
		<>
			<UsersSummary />
			<ActivityByCategory />
			<FeedbackTable />
		</>
	);
};

export default UserActivity;
