import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import {
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	CircularProgress,
	Box,
	LinearProgress
} from '@mui/material';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { ChatContext } from '../../../Contexts';
import getUser from '../../../Helper/getUser';

const Playground = () => {
	const user = getUser();

	// const [playgroundLoading, setPlaygroundLoading] = useState(false);
	const [selectedSimulation, setSelectedSimulation] = useState<any>();
	const [selectedScenario, setSelectedScenario] = useState<any>();
	const [systemPrompt, setSystemPrompt] = useState('');
	const [numberOfTurns, setNumberOfTurns] = useState<any>('');
	const [results, setResults] = useState<any>('');

	const { selectedPlayground, setSelectedPlayground, playgroundLoading, setPlaygroundLoading, setPreviousPlaygrounds } =
		useContext(ChatContext);

	const [scenarios, setScenarios] = useState<any[]>([]); // Use IPrompt[] as the type for promptData
	const token = getToken();

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || ''; // Ensure to handle undefined case
	const { unhandleToken } = useHandleToken();
	const handleStart = async () => {
		try {
			setPlaygroundLoading(true);
			const res = await axios.post(
				`${baseUrl}/api/admin/playground/generate-conversation`,
				{
					systemPrompt,
					numberOfTurns,
					simulation: selectedSimulation,
					scenario: selectedScenario
				},
				{
					headers: {
						Authorization: token || ''
					}
				}
			);

			if (res?.data?.data) {
				const data: any = await getPreviousResults();
				if (data?.length) {
					setSelectedPlayground(data[0]);
				}
				setResults(JSON.stringify(res?.data?.data));
				setPlaygroundLoading(false);
			}
		} catch (error) {
			setPlaygroundLoading(false);
			console.error(error);
			unhandleToken(error);
		}
	};

	useEffect(() => {
		getScenarios();
	}, []);

	const getScenarios = async () => {
		setPlaygroundLoading(true);

		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setScenarios(res.data);
					setPlaygroundLoading(false);
				}
			})
			.catch(e => {
				console.log(e);
				setPlaygroundLoading(false);
				unhandleToken(e);
			});
	};

	const getPreviousResults = async () => {
		try {
			const q = query(collection(db, 'playground'), where('createdBy', '==', user?.id), orderBy('time', 'desc'));
			const playgroundDocSnap = await getDocs(q);
			const prevResults = playgroundDocSnap?.docs;
			setPreviousPlaygrounds(prevResults.map(doc => doc.data()));

			return prevResults.map(doc => doc.data());
		} catch (error) {
			console.error('Error fetching data from Firestore:', error);
		}
	};

	useEffect(() => {
		if (selectedPlayground) {
			setResults(JSON.stringify(selectedPlayground?.conversations));
			setNumberOfTurns(selectedPlayground?.numberOfTurns);
			setSelectedScenario(scenarios?.find(item => item?.scenario_id == selectedPlayground?.scenarioId));
			setSelectedSimulation(scenarios?.find(item => item?.scenario_id == selectedPlayground?.simulationId));
			setSystemPrompt(selectedPlayground?.systemPrompt);
		}
	}, [selectedPlayground]);

	return (
		<>
			{playgroundLoading && <LinearProgress />}
			<div style={{ display: 'flex', gap: '20px' }}>
				<Button
					id="try-again-button-for-playground"
					hidden={true}
					disabled={playgroundLoading}
					onClick={() => {
						setResults('');
						setSelectedPlayground(null);
						setSelectedScenario(undefined);
						setSystemPrompt('');
						setNumberOfTurns('');
						setSelectedSimulation(undefined);
					}}
					className="btn blue-btn mb-3"
				>
					Try again
				</Button>
				<div
					style={{
						width: '80%',
						padding: '20px',
						display: 'flex',
						flexDirection: 'column',

						gap: '25px',
						margin: 'auto'
					}}
				>
					<div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
						<FormControl sx={{ width: '40%' }}>
							<InputLabel id="simulation-select-label">Simulation Name</InputLabel>
							<Select
								// MenuProps={{
								// 	PaperProps: {
								// 		style: {
								// 			transformOrigin: 'top left' // This helps avoid reflows
								// 		}
								// 	}
								// }}
								// autoWidth={true}
								disabled={playgroundLoading}
								labelId="simulation-select-label"
								value={selectedSimulation || ''}
								label="Simulation Name"
								onChange={e => setSelectedSimulation(e.target.value as string)}
							>
								{scenarios
									?.filter(item => item?.parent_id === null)
									.map(scenario => (
										<MenuItem onClick={() => setSelectedSimulation(scenario)} key={scenario.id} value={scenario}>
											{scenario?.scenario_name}
										</MenuItem>
									))}
							</Select>
						</FormControl>

						{selectedSimulation &&
							scenarios.filter(item => item?.parent_id == selectedSimulation?.scenario_id)?.length > 0 && (
								<FormControl sx={{ width: '40%' }}>
									<InputLabel id="scenario-select-label">Scenario Name</InputLabel>
									<Select
										// MenuProps={{
										// 	PaperProps: {
										// 		style: {
										// 			transformOrigin: 'top left' // This helps avoid reflows
										// 		}
										// 	}
										// }}
										// autoWidth={true}
										disabled={playgroundLoading}
										labelId="scenario-select-label"
										value={selectedScenario || ''}
										label="Scenario Name"
										onChange={e => setSelectedScenario(e.target.value as string)}
									>
										{scenarios
											.filter(item => item?.parent_id == selectedSimulation?.scenario_id)
											.map(scenario => (
												<MenuItem onClick={() => setSelectedScenario(scenario)} key={scenario.id} value={scenario}>
													{scenario?.scenario_name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}

						<Button
							disabled={playgroundLoading || !selectedScenario || !numberOfTurns || !systemPrompt}
							variant="contained"
							color="success"
							style={{ width: '80px' }}
							onClick={handleStart}
						>
							Start {playgroundLoading && <CircularProgress size={20} />}
						</Button>
					</div>

					<Box sx={{ width: '200px' }}>
						<TextField
							InputLabelProps={{
								shrink: true // Force the label to shrink when a value is present
							}}
							fullWidth
							disabled={playgroundLoading}
							type="number"
							label="Number of turns"
							value={numberOfTurns}
							onChange={e => setNumberOfTurns(e.target.value)}
						/>
					</Box>

					<TextField
						InputLabelProps={{
							shrink: true // Force the label to shrink when a value is present
						}}
						disabled={playgroundLoading}
						fullWidth
						label="System Prompt"
						multiline
						rows={10}
						value={systemPrompt}
						onChange={e => setSystemPrompt(e.target.value)}
					/>

					{results?.length > 0 &&
						JSON.parse(results)?.map((item: any, index: number) => (
							<div key={index} style={{ display: 'flex' }}>
								<div style={{ minWidth: '90px', maxWidth: '90px', textAlign: 'left' }}>
									{item?.messageType?.toUpperCase()}
								</div>
								<div style={{ width: '2%' }}>:</div>
								<div style={{ width: '100%' }}>{item?.message}</div>
							</div>
						))}

					{/* <TextField
						InputLabelProps={{
							shrink: true // Force the label to shrink when a value is present
						}}
						disabled={playgroundLoading}
						fullWidth
						label="Results"
						multiline
						value={results}
						onChange={e => setResults(e.target.value)}
						inputProps={{ readOnly: true }}
					/> */}
				</div>
			</div>
		</>
	);
};

export default Playground;
