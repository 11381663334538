/* eslint-disable @typescript-eslint/no-empty-function */

import React, { FC, useState } from 'react';

import { defaultFeedbackContent } from '../Helper';

import { IChat, IConversation, IFeedback, IRecommendation, IChatContextType } from '../types';

// Create a context with the specified type
export const ChatContext = React.createContext<IChatContextType>({
	messages: [],
	feedbackContent: defaultFeedbackContent,
	recommendations: [],
	conversations: [],
	selectedConversationId: undefined,
	chatLoading: false,
	chatProcessing: false,
	coachSays: false,
	audioQueue: [],
	isPlaying: false,
	micVisual: false,
	setMessages: () => {},
	setFeedbackContent: () => {},
	setRecommendations: () => {},
	setConversations: () => {},
	setSelectedConversationId: () => {},
	setChatLoading: () => {},
	setChatProcessing: () => {},
	setCoachSays: () => {},
	setAudioQueue: () => {},
	setPlaying: () => {},
	meetings: [],
	setMeetings: () => {},
	setMicVisual: () => {},

	previousPlaygrounds: [],
	setPreviousPlaygrounds: () => {},
	selectedPlayground: null,
	setSelectedPlayground: () => {},
	playgroundLoading: false,
	setPlaygroundLoading: () => {},

	simulationFeedbackRequire: false,
	setSimulationFeedbackRequire: () => {},

	messageType: '',
	setMessageType: () => {},

	isMessageAddedToDb: false,
	setIsMessageAddedToDb: () => {},

	isTheLastMessage: false,
	setIsTheLastMessage: () => {},

	isConnected: false,
	setIsConnected: () => {},

	displayTryAgain: false,
	setDisplayTryAgain: () => {}
});

// Create a provider component to wrap your app and provide the context values
export const ChatContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	const [messages, setMessages] = useState<IChat[]>([]);
	const [meetings, setMeetings] = useState<any[]>([]);
	const [micVisual, setMicVisual] = useState<boolean>(false);
	const [feedbackContent, setFeedbackContent] = useState<IFeedback[]>(defaultFeedbackContent);
	const [recommendations, setRecommendations] = useState<IRecommendation[]>([]);
	const [conversations, setConversations] = useState<IConversation[]>([]);
	const [selectedConversationId, setSelectedConversationId] = useState<string | undefined>();
	const [chatLoading, setChatLoading] = useState<boolean>(false);
	const [chatProcessing, setChatProcessing] = useState<boolean>(false);
	const [coachSays, setCoachSays] = useState<boolean>(false);
	const [audioQueue, setAudioQueue] = useState<any[]>([]);
	const [isPlaying, setPlaying] = useState<boolean>(false);
	const [simulationFeedbackRequire, setSimulationFeedbackRequire] = useState<boolean>(false);

	const [previousPlaygrounds, setPreviousPlaygrounds] = React.useState<any[]>([]);
	const [selectedPlayground, setSelectedPlayground] = useState<any>(null);
	const [playgroundLoading, setPlaygroundLoading] = useState<boolean>(false);

	const [messageType, setMessageType] = useState<string>('text');
	const [isMessageAddedToDb, setIsMessageAddedToDb] = useState<boolean>(false);
	const [isTheLastMessage, setIsTheLastMessage] = useState<boolean>(false);
	const [isConnected, setIsConnected] = useState(false);

	const [displayTryAgain, setDisplayTryAgain] = useState<boolean>(false);

	return (
		<ChatContext.Provider
			value={{
				messages,
				feedbackContent,
				recommendations,
				conversations,
				selectedConversationId,
				chatLoading,
				chatProcessing,
				coachSays,
				meetings,
				simulationFeedbackRequire,
				audioQueue,
				isPlaying,
				micVisual,
				previousPlaygrounds,
				selectedPlayground,
				setMessages,
				setFeedbackContent,
				setRecommendations,
				setConversations,
				setSelectedConversationId,
				setChatLoading,
				setChatProcessing,
				setCoachSays,
				setMeetings,
				setSimulationFeedbackRequire,
				setAudioQueue,
				setPlaying,
				setMicVisual,
				setPreviousPlaygrounds,
				setSelectedPlayground,
				playgroundLoading,
				setPlaygroundLoading,
				messageType,
				setMessageType,
				isMessageAddedToDb,
				setIsMessageAddedToDb,
				isTheLastMessage,
				setIsTheLastMessage,
				isConnected,
				setIsConnected,
				displayTryAgain,
				setDisplayTryAgain
			}}>
			{children}
		</ChatContext.Provider>
	);
};
