import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};
interface SingleCohortProps {
	setSingleCohortOpen: (singleCohortOpen: boolean) => void;
	selectedCohortId: unknown;
}
interface User {
	id: string;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name: string;
	company: string;
	role: string;
	created_at: string;
	deleted_at: string | null;
}

interface Company {
	id: string;
	name: string;
	contact: {
		id: number;
		email: string;
		first_name: string;
		last_name: string;
	};
}

interface CohortData {
	id: string;
	name: string;
	company: Company;
	start_date: string;
	end_date: string;
	created_at: string;
	deleted_at: string | null;
	created_by: string;
	deleted_by: string | null;
	scenarios: string[];
	users: User[];
}
const SingleCohort: React.FC<SingleCohortProps> = ({ setSingleCohortOpen, selectedCohortId }) => {
	const [cohortData, setCohortData] = useState<CohortData | null>(null);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getCohort();
	}, []);

	const getCohort = async () => {
		try {
			await axios
				.get(`${baseUrl}/getacohort/${selectedCohortId}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data.success) {
						setCohortData(res.data.data);
					}
				})
				.catch(e => {
					console.log(e);
				});
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		}
	};

	return (
		<>
			<Box sx={style}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
					<Typography variant="h6">Cohort</Typography>
					<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setSingleCohortOpen(false)}>
						<HighlightOffOutlinedIcon />
					</Button>
				</Box>
				{cohortData === null ? (
					'Loading Data'
				) : (
					<Box>
						<Box sx={{ display: 'flex', marginBottom: '5px' }}>
							<Typography sx={{ width: '50%' }}>Cohort Name</Typography>
							<Typography sx={{ width: '50%', fontWeight: '700' }}>{cohortData?.name || ''}</Typography>
						</Box>
						<Box sx={{ display: 'flex', marginBottom: '5px' }}>
							<Typography sx={{ width: '50%' }}>Company</Typography>
							<Typography sx={{ width: '50%', fontWeight: '700' }}>{cohortData?.company?.name || ''}</Typography>
						</Box>
						<Box sx={{ display: 'flex', marginBottom: '5px' }}>
							<Typography sx={{ width: '50%' }}>Scenerio Category</Typography>
							<Box sx={{ width: '50%' }}>
								<Typography sx={{ fontWeight: '700' }}>
									{Array.isArray(cohortData?.scenarios) &&
										cohortData?.scenarios.length > 0 &&
										cohortData?.scenarios.map((items: any, index: number) => {
											return (
												<>
													<Box sx={{ display: 'flex', gap: '10px' }} key={index}>
														<Typography>{index + 1}.</Typography>
														<Typography sx={{ fontWeight: '700' }}>{items.scenario_name}</Typography>
													</Box>
												</>
											);
										})}
								</Typography>
							</Box>
						</Box>
						<hr></hr>
						<Box sx={{ display: 'flex', marginBottom: '5px' }}>
							<Typography sx={{ width: '50%' }}>Coach</Typography>
							<Box sx={{ width: '50%' }}>
								<Typography sx={{ fontWeight: '700' }}>
									{Array.isArray(cohortData?.users) &&
										cohortData?.users.length > 0 &&
										cohortData?.users
											.filter((itemr: any) => itemr.role?.name === 'coach')
											.map((items: User, index: number) => {
												return (
													<>
														<Box sx={{ display: 'flex', gap: '10px' }} key={index}>
															<Typography sx={{ fontWeight: '700' }}>
																{items.first_name + (items.last_name ? ' ' + items.last_name : '')}
															</Typography>
														</Box>
													</>
												);
											})}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', marginBottom: '5px' }}>
							<Typography sx={{ width: '50%' }}>Student</Typography>
							<Box sx={{ width: '50%' }}>
								<Typography sx={{ fontWeight: '700' }}>
									{Array.isArray(cohortData?.users) &&
										cohortData?.users.length > 0 &&
										cohortData?.users
											.filter((itemr: any) => itemr.role?.name === 'client')
											.map((items: User, index: number) => {
												return (
													<>
														<Box sx={{ display: 'flex', gap: '10px' }} key={index}>
															<Typography>{index + 1}.</Typography>
															<Typography sx={{ fontWeight: '700' }}>
																{items.first_name + (items.last_name ? ' ' + items.last_name : '')}
															</Typography>
														</Box>
													</>
												);
											})}
								</Typography>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default SingleCohort;
