import React, { useState } from 'react';
import { Box, Modal, Typography, IconButton, TextField, Rating, Button, Snackbar, Alert, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { getToken } from '../../Helper/GetToken';

interface UserFeedbackModalProps {
	open: boolean;
	onClose: () => void;
	cohortId: number;
	scenarioId: number;
	childScenarioId: number | null;
	stage: string;
	conversationId?: string;
	checkstatus?: () => void;
}

const UserFeedbackModal: React.FC<UserFeedbackModalProps> = ({
	open,
	onClose,
	cohortId,
	scenarioId,
	childScenarioId,
	stage,
	conversationId,
	checkstatus
}) => {
	const [technicalPoint, setTechnicalPoint] = useState<number | null>(0);
	const [contentPoint, setContentPoint] = useState<number | null>(0);
	const [userExperiencePoint, setUserExperiencePoint] = useState<number | null>(0);
	const [feedback, setFeedback] = useState<string>('');
	const [toastOpen, setToastOpen] = useState(false);
	const [errorToastOpen, setErrorToastOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>(''); // To display validation error message
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();

	const resetForm = () => {
		setTechnicalPoint(0);
		setContentPoint(0);
		setUserExperiencePoint(0);
		setFeedback('');
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		// Check if all required fields are filled
		if (technicalPoint === 0 || contentPoint === 0 || userExperiencePoint === 0 || !feedback.trim()) {
			setErrorMessage('Please provide ratings for all fields and feedback.');
			setErrorToastOpen(true);
			return; // Prevent form submission if any field is empty
		}

		const feedbackData = {
			cohortId,
			scenarioId,
			childScenarioId,
			stage,
			conversationId,
			technical_point: technicalPoint,
			content_point: contentPoint,
			userExperience_point: userExperiencePoint,
			feedback
		};
		try {
			await axios.post(`${baseUrl}/feedback/add-feedback`, feedbackData, {
				headers: {
					Authorization: token
				}
			});
			setToastOpen(true);
			resetForm();
			onClose();
			checkstatus && checkstatus();
		} catch (error) {
			console.error('Error submitting feedback:', error);
			setErrorToastOpen(true);
		}
	};

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 450,
						bgcolor: 'background.paper',
						boxShadow: 24,
						borderRadius: 2,
						p: 4
					}}
				>
					<Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
						<Typography variant="h6" fontWeight="bold" color="primary">
							We Value Your Feedback! Rate this session
						</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Divider sx={{ mb: 2 }} />

					<form onSubmit={handleSubmit}>
						<Box mb={3}>
							<Typography variant="subtitle1" fontWeight="medium" color="textSecondary">
								Technical Quality
							</Typography>
							<Rating
								name="technical_point"
								value={technicalPoint}
								onChange={(e, newValue) => setTechnicalPoint(newValue)}
								size="large"
							/>
						</Box>

						<Box mb={3}>
							<Typography variant="subtitle1" fontWeight="medium" color="textSecondary">
								Content Quality
							</Typography>
							<Rating
								name="content_point"
								value={contentPoint}
								onChange={(e, newValue) => setContentPoint(newValue)}
								size="large"
							/>
						</Box>

						<Box mb={3}>
							<Typography variant="subtitle1" fontWeight="medium" color="textSecondary">
								User Experience
							</Typography>
							<Rating
								name="userExperience_point"
								value={userExperiencePoint}
								onChange={(e, newValue) => setUserExperiencePoint(newValue)}
								size="large"
							/>
						</Box>

						<Box mb={3}>
							<TextField
								label="How can we improve?"
								variant="outlined"
								fullWidth
								multiline
								rows={4}
								value={feedback}
								onChange={e => setFeedback(e.target.value)}
								placeholder="Share your thoughts here..."
							/>
						</Box>

						{/* Display validation error message */}
						{errorMessage && (
							<Typography variant="body2" color="error" sx={{ mb: 2 }}>
								{errorMessage}
							</Typography>
						)}

						<Box display="flex" justifyContent="flex-end">
							<Button variant="contained" color="primary" type="submit" sx={{ textTransform: 'none', px: 3 }}>
								Submit
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>

			{/* Snackbar for success message */}
			<Snackbar
				open={toastOpen}
				autoHideDuration={3000}
				onClose={() => setToastOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert onClose={() => setToastOpen(false)} severity="success" sx={{ width: '100%' }}>
					Feedback submitted successfully!
				</Alert>
			</Snackbar>

			{/* Snackbar for error message */}
			<Snackbar
				open={errorToastOpen}
				autoHideDuration={3000}
				onClose={() => setErrorToastOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert onClose={() => setErrorToastOpen(false)} severity="error" sx={{ width: '100%' }}>
					Failed to submit feedback.
				</Alert>
			</Snackbar>
		</>
	);
};

export default UserFeedbackModal;
