export const setUserDetails = (userDetails: any) => ({
	type: 'SET_USER_DETAILS',
	payload: userDetails
});

export const setUserCohort = (cohort: any) => ({
	type: 'SET_USER_COHORT',
	payload: cohort
});

export const clearUserDetails = () => ({
	type: 'CLEAR_USER_DETAILS'
});

export const setEnableVoice = (voiceStatus: any) => ({
	type: 'CHANGE_STATUS',
	payload: voiceStatus
});
export const setRole = (roleName: any) => ({
	type: 'SET_ROLE_NAME',
	payload: roleName
});

export const setSimulationValues = (simulationValues: any) => ({
	type: 'SET_SIMULATION_VALUES',
	payload: simulationValues
});

export const setSimulationStepper = (payload: any) => ({
	type: 'SET_STEP',
	payload: payload
});

export const setConversationId = (conversationId: any) => ({
	type: 'SET_CONVERSATION_ID',
	payload: conversationId
});
export const clearConversationId = () => ({
	type: 'CLEAR_CONVERSATION_ID'
});

// export const setSimulationId = (simulationId)=>({
//   type:"SET_SIMULATION_ID",
//   payload: simulationId,
// })
// export const clearSimulationId = () => ({
//   type: "CLEAR_SIMULATION_ID",
// });
export const setSimulationStep = (simulationStep: any) => ({
	type: 'SET_SIMULATION_STEP',
	payload: simulationStep
});
export const clearSimulationStep = () => ({
	type: 'CLEAR_SIMULATION_STEP'
});
export const setSelectedUser = (userId: any) => ({
	type: 'SET_USER_ID',
	payload: userId
});
export const clearSelectedUser = () => ({
	type: 'CLEAR_USER_ID'
});

export const setSelectedCompletedSimulationMessages = (messages: any, simulationId: any) => ({
	type: 'SET_SIMULATION_DETAILS',
	payload: { messages, simulationId }
});
export const clearSelectedCompletedSimulationMessages = () => ({
	type: 'clearSimulationDetails'
});
export const setRoleCategory = (category: any) => ({
	type: 'SET_ROLE_CATEGORY',
	payload: category
});
export const clearRoleCategory = () => ({
	type: 'CLEAR_ROLE_CATEGORY'
});
export const setAuthStatus = (status: boolean) => ({
	type: 'SET_AUTH_STATUS',
	payload: status
});

export const clearAuthStatus = () => ({
	type: 'CLEAR_AUTH_STATUS'
});

export const setLastConversation = (message: any) => ({
	type: 'SET_LAST_CONVERSATION',
	payload: message
});

export const clearLastConverstaion = () => ({
	type: 'CLEAR_LAST_CONVERTSATION'
});

export const setLevel = (level: number) => ({
	type: 'SET_LEVEL',
	payload: level
});

export const clearLevel = () => ({
	type: 'CLEAR_LEVEL'
});

export const setCohortSimulation = (simulations: []) => ({
	type: 'SET_COHORT_SIMULATION',
	payload: simulations
});

export const clearCohortSimulation = () => ({
	type: 'CLEAR_COHORT_SIMULATION'
});

export const setBadgeSimulation = (id: any) => ({
	type: 'SET_BADGE_SIMULATION',
	payload: id
});

export const clearBadgeSimulation = () => ({
	type: 'CLEAR_BADGE_SIMULATION'
});

export const setSelectedCohort = (id: any) => ({
	type: 'SET_SELECTED_COHORT',
	payload: id
});
export const clearSelectedCohort = () => ({
	type: 'CLEAR_SELECTED_COHORT'
});

export const setSelectedSimulation = (Data: any) => ({
	type: 'SET_SELECTED_SIMULATION',
	payload: Data
});
export const clearSelectedSimulation = () => ({
	type: 'CLEAR_SELECTED_SIMULATION'
});
