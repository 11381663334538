import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	overflowY: 'scroll',
	maxHeight: '95vh'
};

interface CohortSummaryProps {
	setSummaryOpen: (editOpen: boolean) => void;
	selectedCohort: any;
}

const CohortSummary: React.FC<CohortSummaryProps> = ({ setSummaryOpen, selectedCohort }) => {
	const [selectedScenarios, setSelectedScenarios] = useState<any[]>([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();

	const downloadHandler = async () => {
		try {
			const scenarioIds = selectedScenarios.map(scenario => scenario.scenario_id);
			const response = await axios.post(
				`${baseUrl}/cohort/summary`,
				{
					cohortId: selectedCohort.id,
					scenarioIds
				},
				{
					headers: {
						Authorization: token
					}
				}
			);

			const blob = new Blob([response.data], { type: 'text/csv' });
			const downloadUrl = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = downloadUrl;
			link.download = 'cohort-summary.csv';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error('Error downloading summary:', error);
		}
	};
	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Cohort Summary</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setSummaryOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				<Autocomplete
					multiple
					options={selectedCohort.scenario}
					getOptionLabel={(option: any) => option.scenario_name || ''}
					value={selectedScenarios}
					onChange={(event, newValue) => setSelectedScenarios(newValue)}
					fullWidth
					sx={{
						'& .MuiAutocomplete-listbox': {
							maxHeight: 200,
							overflowY: 'auto'
						},
						'& .MuiAutocomplete-popper': {
							zIndex: 1301
						}
					}}
					renderInput={params => <TextField {...params} label="Select Scenarios" />}
				/>
				<Button variant="outlined" size="large" startIcon={<DownloadIcon />} onClick={downloadHandler}>
					Download
				</Button>
			</Box>
		</Box>
	);
};

export default CohortSummary;
