import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearSelectedCompletedSimulationMessages,
	setRole,
	setRoleCategory
} from '../../../../Helper/Redux/Action/userAction';
import { getToken, useHandleToken } from '../../../../Helper/GetToken';
import axios from 'axios';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
// Define the type for the props in TypeScript
type SimulationCategoryProps = {
	setIsCategorySelected: (selected: boolean) => void;
};

const SimulationCategory: React.FC<SimulationCategoryProps> = ({ setIsCategorySelected }) => {
	const { category } = useSelector((state: any) => state?.roleCategory);
	const [categoryConv, setCategoryConv] = useState([]);
	const dispatch = useDispatch();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		dispatch(clearSelectedCompletedSimulationMessages());
		getScenarios();
	}, []);

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/cohortscenerio`, {
				headers: {
					Authorization: token
				}
				// params: { id: 5 }
			})
			.then(res => {
				if (res.data) {
					setCategoryConv(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const handleMember = (member: { name?: string; id?: string; description?: string }) => {
		dispatch(setRoleCategory(member));
		dispatch(setRole(null));
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Box sx={{ textAlign: 'center', width: '80%', padding: '20px' }}>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: '700', fontSize: '28px' }}>
						Pick a simulation category
					</Typography>
				</Box>

				<Box>
					<Typography sx={{ textAlign: 'center', margin: '10px 0' }}>
						Select which category you’d like to start with
					</Typography>
				</Box>
				<Box
					sx={{
						margin: '50px auto'
					}}
				>
					<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} justifyContent={'center'}>
						{categoryConv.map((item: any, index: number) => {
							// console.log(item.cohortId);

							return (
								<Grid key={index} item xs={6}>
									<Box
										sx={{
											borderRadius: '8px',
											padding: '24px',
											height: '150px',
											textAlign: 'start',
											display: 'flex',
											justifyContent: 'space-between',
											border: '1px solid #4589FF',
											background: category?.scenario_id === item.scenario_id ? '  #E5F3FE' : ''
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											{category?.scenario_id === item.scenario_id ? (
												<RadioButtonCheckedIcon
													onClick={() => {
														dispatch(setRoleCategory(null));
														setIsCategorySelected(false);
													}}
													sx={{ cursor: 'pointer', color: '#4589FF', fontSize: '24px' }}
												/>
											) : (
												<RadioButtonUncheckedIcon
													onClick={() => {
														handleMember(item);
													}}
													sx={{ cursor: 'pointer', color: '#4589FF', fontSize: '24px' }}
												/>
											)}
											<Box sx={{ marginLeft: '24px' }}>
												<Typography
													sx={{
														fontWeight: '600',
														fontSize: '24px',
														marginBottom: '14px',
														minHeight: '60px',
														display: 'flex',
														gap: '10px',
														alignItems: 'center'
													}}
												>
													<img
														alt="Loading"
														src={item?.picture?.data}
														style={{
															width: '48px',
															height: '48px',
															marginRight: '10px'
														}}
													/>
													{item?.scenario_name}
												</Typography>
												<Typography sx={{ alignItems: 'left', fontSize: '16px', fontWeight: '400' }}>
													{item.description}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Box>

				{categoryConv?.length > 0 && (
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							'@media (max-width: 768px)': {
								px: '10px'
							}
						}}
					>
						<Button
							disabled={!category}
							variant="outlined"
							sx={{ ...styles.Button }}
							onClick={() => {
								setIsCategorySelected(true);
							}}
						>
							Next
						</Button>
					</Box>
				)}

				{/* <Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: '10px',
						' @media(max-width:747px)': {
							flexDirection: 'column'
						}
					}}>
					{categoryConv.map((items: any, index: number) => {
						return (
							<>
								<Box
									sx={{
										borderRadius: '8px',
										padding: '32px',
										width: '250px',
										textAlign: 'start',
										background: '#BCE0FC',
										minHeight: '380px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between'
									}}
									key={index}>
									<Box>
										<Typography
											sx={{
												fontWeight: '700',
												fontSize: '20px',
												marginBottom: '15px',
												minHeight: '60px',
												display: 'flex',
												gap: '10px',
												alignItems: 'center'
											}}>
											<img
												alt="Loading"
												src={items?.picture?.data}
												style={{
													width: '24px',
													height: '24px',
													marginRight: '10px'
												}}
											/>
											{items?.scenario_name}
										</Typography>

										<Typography sx={{ alignItems: 'left', fontSize: '16px' }}>{items.description}</Typography>
									</Box>
									<Box
										sx={{
											marginBottom: '20px',
											width: '100%',
											display: 'flex',
											justifyContent: 'center'
										}}>
										{category?.scenario_id === items.scenario_id ? (
											<Button
												variant="outlined"
												sx={{
													width: '100%',
													padding: '12px',
													borderRadius: '100px',
													fontFamily: 'Inter',
													textTransform: 'capitalize',
													fontSize: '18px'
												}}
												onClick={() => {
													dispatch(setRoleCategory(null));
													setIsCategorySelected(false);
												}}>
												Selected
											</Button>
										) : (
											<Button
												sx={{
													width: '100%',
													padding: '12px',
													borderRadius: '100px',
													fontFamily: 'Inter',
													textTransform: 'capitalize',
													fontSize: '18px'
												}}
												variant="contained"
												onClick={() => {
													handleMember(items);
												}}>
												Select
											</Button>
										)}
									</Box>
								</Box>
							</>
						);
					})}
				</Box> */}
			</Box>
		</div>
	);
};

export default SimulationCategory;

const styles = {
	Button: {
		borderRadius: '100px',
		border: '1px solid #1C75BA',
		p: '10px 90px',
		fontSize: '18px',
		fontWeight: 500,
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};
