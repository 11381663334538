import {
	Box,
	Button,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from '@mui/material';
import React, { useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import CancelIcon from '@mui/icons-material/Cancel';
import { fireStorage } from '../../../config/firebase';
import DescriptionIcon from '@mui/icons-material/Description';
import { getFileNameFromUrl } from '../../../Helper/encodeURI';
import AudioCard from './AudioCard';
import { voiceModalOptions } from '../../../Helper/TextToSpeech';
import { Label } from '@mui/icons-material';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};

interface CreateCategoryProps {
	setOpen: (open: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	imageData: unknown[];
	parent_id?: number;
	video_url?: any;
	voice_modal?: any;
}

type scenarioType = {
	scenario_name: string;
	parent_id: number | null;
	category: string;
	picture_file_id: string;
	description: string;
	role_name: string;
	video_url: string | null;
	voice_modal: string | null;
	badge_file_id: string | null;
};

const CreateCategory: React.FC<CreateCategoryProps> = ({ setOpen, imageData, setIsRefresh, parent_id }) => {
	const [scenarioData, setScenarioData] = useState<scenarioType>({
		scenario_name: '',
		parent_id: parent_id || null,
		category: '',
		picture_file_id: '',
		description: '',
		role_name: '',
		video_url: null,
		voice_modal: null,
		badge_file_id: null
	});
	const [isDisable, setIsDisable] = useState(true);
	const token = getToken();
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const formHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
		if (isDisable) {
			setIsDisable(false);
		}
		const { name, value } = e.target;
		setScenarioData(prevState => ({
			...prevState,
			[name as string]: value,
			...(name === 'scenario_name' && { category: value as string }) // Update category if scenario_name is updated
		}));
	};

	const [video, setVideo] = useState<File | string | null>(null);
	const [loadingForVideo, setLoadingForVideo] = useState(false);
	const [isVideo, setIsVideo] = useState<boolean>(false);
	const [selectedVoiceModal, setSelectedVoiceModal] = useState<any>(null);
	const { unhandleToken } = useHandleToken();
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (file) {
			setVideo(file);
			const fileType = file?.type;
			const isVideoFile = fileType?.includes('video');
			const isTextFile = fileType?.includes('text');

			if (isVideoFile) {
				setIsVideo(true);
			} else if (isTextFile) {
				setIsVideo(false);
			}
			setIsDisable(false);
		}
	};

	const handleVideoDelete = async () => {
		setIsDisable(false);
		setVideo(null);
	};

	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisable(true);

		let videoUrl: string | null = null;

		if (video && typeof video !== 'string') {
			setLoadingForVideo(true);
			const fileName = `${new Date().getTime()}-${video?.name}`;
			const storageRef = await ref(fireStorage, isVideo ? `scenario_videos/${fileName}` : `scenario_texts/${fileName}`);

			const snapshot = await uploadBytes(storageRef, video);
			const downloadURL = await getDownloadURL(snapshot.ref);
			console.log('Uploaded file URL:', downloadURL);
			videoUrl = downloadURL;
			setLoadingForVideo(false);
		}

		await axios
			.post(
				`${baseUrl}/api/scenario/addScenario`,
				{ ...scenarioData, video_url: videoUrl, voice_modal: selectedVoiceModal },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(res => {
				if (res?.data?.success) {
					setScenarioData({
						scenario_name: '',
						parent_id: null,
						category: '',
						picture_file_id: '',
						description: '',
						role_name: '',
						video_url: videoUrl,
						voice_modal: null,
						badge_file_id: null
					});
					setOpen(false);
					setIsRefresh(true);
				}
				setIsDisable(false);
			})
			.catch(e => {
				console.log(e);
				setIsDisable(false);
				unhandleToken(e);
			});
	};
	return (
		<Box sx={style}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
				<Typography variant="h6">Add Category</Typography>
				<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setOpen(false)}>
					<HighlightOffOutlinedIcon />
				</Button>
			</Box>
			<form onSubmit={submitHandler}>
				<FormControl fullWidth sx={{ gap: 2 }}>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Scenario Name"
							name="scenario_name"
							type="text"
							value={scenarioData.scenario_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Description"
							name="description"
							type="text"
							value={scenarioData.description}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<TextField
							required
							id="outlined-required"
							label="Role"
							name="role_name"
							type="text"
							value={scenarioData.role_name}
							onChange={formHandler}
							fullWidth
						/>
					</Box>
					<Box>
						<Typography>Profile</Typography>
						<Select
							name="picture_file_id"
							value={scenarioData.picture_file_id}
							onChange={formHandler}
							fullWidth
							required
						>
							{Array.isArray(imageData) &&
								imageData
									.filter((item: any) => item.usage !== 'badge') // Filter out items with usage equal to 'badge'
									.map((item: any, index: number) => (
										<MenuItem key={index} value={item.id}>
											<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
												<img alt={item.name} src={item.data} style={{ width: '20px', height: '20px' }} />
												<Typography>{item.name}</Typography>
											</Box>
										</MenuItem>
									))}
						</Select>
					</Box>
					{parent_id ? (
						''
					) : (
						<Box>
							<Typography>Badge</Typography>
							<Select
								name="badge_file_id"
								value={scenarioData?.badge_file_id || undefined}
								onChange={formHandler}
								inputProps={{ 'aria-label': 'Without label' }}
								fullWidth
								required
							>
								{Array.isArray(imageData) &&
									imageData
										.filter((item: any) => item.usage === 'badge') // Filter for items with usage equal to 'badge'
										.map((item: any, index: number) => (
											<MenuItem key={index} value={item.id}>
												<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
													<img alt={item.name} src={item.data} style={{ width: '20px', height: '20px' }} />
													<Typography>{item.name}</Typography>
												</Box>
											</MenuItem>
										))}
							</Select>
						</Box>
					)}

					<Box>
						<label>Voice Modals</label>
						<div
							id="audio-voice"
							style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '100%', marginTop: '4px' }}
						>
							{Array.isArray(imageData) &&
								voiceModalOptions.map((item: any, index: number) => (
									<AudioCard
										item={item}
										key={index}
										setSelectedVoiceModal={e => {
											setIsDisable(false);
											setSelectedVoiceModal(e);
										}}
										selectedVoiceModal={selectedVoiceModal}
									/>
								))}
						</div>
					</Box>
					<Box>
						{loadingForVideo ? (
							<LinearProgress />
						) : video ? (
							<Box display={'flex'} width={'100%'} gap={1}>
								{isVideo ? (
									<video
										controls
										style={{ maxWidth: '300px' }}
										src={typeof video === 'string' ? video : URL.createObjectURL(video)}
									>
										Your browser does not support to load the video.
									</video>
								) : (
									<div
										onClick={() => {
											if (typeof video === 'string') {
												window.open(video, '_blank');
											}
										}}
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 1,
											width: '100%',
											cursor: typeof video === 'string' ? 'pointer' : 'auto'
										}}
									>
										<DescriptionIcon color="primary" fontSize="large" />
										<p>{typeof video !== 'string' ? video?.name && video?.name : getFileNameFromUrl(video)}</p>
									</div>
								)}
								<CancelIcon onClick={handleVideoDelete} sx={{ color: 'red', cursor: 'pointer' }} />
							</Box>
						) : (
							<input
								aria-label="uploadFile"
								accept=".mp4,.webm,.ogg,.txt"
								id="contained-button-file"
								type="file"
								onChange={handleFileChange}
							/>
						)}
					</Box>
					<Box>
						<Button type="submit" disabled={isDisable}>
							Submit
						</Button>
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default CreateCategory;
